import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {AgGridReact} from "ag-grid-react";
import useWindowDimensions from "hooks/Dimensions";
import {TradeCaptureContext} from "./context";
import { dateToString,
    formatDateString,
    stringToDate,
    yearMonthDayToPickerDateFormat} from "utils/FormatUtils";
import {useHistory, useLocation} from 'react-router-dom';
import {
    cellAlignment,
    cellStyleChecker,
    numberFormat3FractionalDigits,
    numberFormat5FractionalDigits,
    numberFormatter
} from "utils/NumberUtils";
import {
    DEFAULT_GRID_PAGINATION_SIZE,
    ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE,
    GRID_COLUMN_PROPERTY
} from "utils/Constants";
import * as Routes from "routes/Routes";
import {addPropertiesToGridColumns, getDisplayedNumberOfGridRows} from "../../utils/gridColumnUtils";
import {UtilsContext} from "../../context/utils";
import ScrollButton from "../../components/AgGridToolbar/ToolBarButtons/ScrollButton";
export default function TradeEntryResult() {
    const {showButton, setShowButton} = useContext(UtilsContext);
    const [gridApi, setGridApi] = useState(null);
    const {tradeCaptureDisplayState, setTradeCaptureDisplayState} = useContext(TradeCaptureContext);
    const [rowData, setRowData] = useState([]);
    const {isMobile} = useWindowDimensions();

    const myGrid = useRef(null);
    const location = useLocation();
    let history = useHistory();

    function navigateToPage(path) {
        history.push(path);
    }

    useEffect(() => {
        tradeCaptureDisplayState.currentPage = ""
    }, [location])


    useEffect(() => {
        setRowData(tradeCaptureDisplayState['tradeEntry']['resData']);
    }, [tradeCaptureDisplayState])

    const defaultColDef = {
        sortable:true,
        resizable:true,
        floatingFilter:true,
        filter:true,
        enableCellChangeFlash:true,
        lockPinned:!!isMobile,
    };

    const columnDefs = [
        {headerName: 'View', field:'', cellRenderer: "viewCellRenderer"},
        {headerName: '', field:'tradeCancelIndicator', hide:true, suppressColumnsToolPanel:true},
        {headerName: 'Status', field: 'status', cellRenderer: "statusRenderer"},
        {headerName: 'Type', field: 'accountTypeForDisplay'},
        {headerName: 'Trade Date', field: 'tradeDate', valueGetter: (params)=>stringToDate(dateToString(params.data.tradeDate))},
        {headerName: 'Settle Date', field: 'settleDate', valueGetter: (params)=>stringToDate(dateToString(params.data.settleDate))},
        {headerName: 'B / S', field: 'tradeTypeForDisplay'},
        {headerName: 'OC', field: 'openCloseCode'},
        {headerName: 'Option', field: 'securityOption'},
        {headerName: 'Security', field: 'securityCode'},
        {headerName: 'Quantity', field: 'quantity', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'PX', field: 'price', valueFormatter: numberFormat3FractionalDigits,cellStyle: cellAlignment},
        {headerName: 'CCY', field: 'currency'},
        {headerName: 'FxUSD', field: 'fxUSDRate', valueFormatter: numberFormat5FractionalDigits,cellStyle: cellAlignment},
        {headerName: 'Comm', field: 'commission', valueFormatter: numberFormat3FractionalDigits, cellStyle: cellStyleChecker},
        {headerName: 'Comm Type', field: 'commissionType'},
        {headerName: 'Interest', field: 'accruedInterest', valueFormatter: numberFormat5FractionalDigits,cellStyle: cellAlignment},
        {headerName: 'Brk', field: 'brokerCode'},
        {headerName: 'Effective', field: 'effectiveDate', valueGetter: (params)=>stringToDate(dateToString(params.data.effectiveDate))},
        {headerName: 'ClientID', field: 'clientId'},
        {headerName: 'Src', field: 'source'},
        {headerName: 'OrigUser', field: 'originalUserId'},
        {headerName: 'OrigUserStampDate', field: 'originalDate', valueGetter: (params)=>formatDateString(params.data.originalDate, "MM/DD/yyyy hh:mm:ss")},
        {headerName: 'File Name', field: 'fileName'},
        {headerName: 'ID', field: 'tradeUploadId'},
        {headerName: 'cdBlockIdClient', field: 'cdBlockIdClient', hide:true, suppressColumnsToolPanel:true},
        {headerName: 'version', field: 'version', hide:true, suppressColumnsToolPanel:true},
        {headerName: 'migrated', field: 'migrated',hide: true, suppressColumnsToolPanel: true},
    ];

    const updatedColumnDefs = useMemo(() => addPropertiesToGridColumns(columnDefs, GRID_COLUMN_PROPERTY), []);

    const onViewClicked = (props) => {
        const newTradeState = {...tradeCaptureDisplayState};
        newTradeState.tradeEntry.managerId = props.managerId
        newTradeState.tradeEntry.accountId = props.accountId
        newTradeState.tradeEntry.accountType = props.accountType.trim()
        newTradeState.tradeEntry.tradeType = props.tradeType.trim()
        newTradeState.tradeEntry.quantity = props.quantity
        newTradeState.tradeEntry.securityOption = props.securityOption
        newTradeState.tradeEntry.security = props.securityCode
        newTradeState.tradeEntry.price = props.price
        newTradeState.tradeEntry.status = props.status
        newTradeState.tradeEntry.currency = props.currency ? props.currency.trim() : ''
        newTradeState.tradeEntry.brokerCode = props.brokerCode ? props.brokerCode.trim() : ''
        newTradeState.tradeEntry.commissionType = props.commissionType
        newTradeState.tradeEntry.commission = props.commission
        newTradeState.tradeEntry.tradeDate = yearMonthDayToPickerDateFormat(props.tradeDate ?props.tradeDate.toString():'')
        newTradeState.tradeEntry.settleDate = yearMonthDayToPickerDateFormat(props.settleDate ?props.settleDate.toString():'')
        newTradeState.tradeEntry.accruedInterest = props.accruedInterest
        newTradeState.tradeEntry.comments = props.comments
        newTradeState.tradeEntry.clientId = props.clientId
        newTradeState.tradeEntry.openClose = props.openClose
        newTradeState.tradeEntry.indTradeCancel = props.tradeCancelIndicator
        newTradeState.tradeEntry.fxUSDRate = props.fxUSDRate
        newTradeState.tradeEntry.tradeBridgeTradeId = props.tradeBridgeTradeId
        newTradeState.tradeEntry.version = props.version
        newTradeState.tradeEntry.dbSource = props.dbSource
        newTradeState.tradeEntry.cdBlockIdClient = props.cdBlockIdClient
        newTradeState.tradeEntry.migrated = props.migrated

        setTradeCaptureDisplayState(newTradeState)
    }

    const viewCellRenderer = function (props){
        return (<>
            {
                <a className="td-link" href="#" onClick={() => {
                    onViewClicked(props.data);
                    navigateToPage(Routes.TRADECAPTURE_ENTRY + "/" + props.data.status)
                }}>View</a>
            }
        </>);
    }

    const statusRenderer = function (props){
        return(<>
            <span className={props.data.status==='CANCELLED' ? 'red-cell' : 'green-cell'} >{props.data.status}</span>
        </>);
    }
    const onDataRendered = (params) => {
        setShowButton(getDisplayedNumberOfGridRows(gridApi) >= ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE);
    }

    const onPaginationChange = (params) => {
        setShowButton(getDisplayedNumberOfGridRows(gridApi) >= ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE);
    }
    const onGridReady = (params) => {
        setGridApi(params.api);
    }

    return(<>
        <Container fluid>
            <Row className="justify-content-start navbar py-4">
                <Col xs={12} className="pl-2">
                    <h1>
                        Trade Entry Result
                    </h1>
                </Col>
            </Row>
            <div
                ref={myGrid}
                id="myGrid"
                className="ag-theme-alpine">
                <AgGridReact
                    frameworkComponents={{
                        statusRenderer: statusRenderer,
                        viewCellRenderer:viewCellRenderer
                    }}
                    paginationPageSize={DEFAULT_GRID_PAGINATION_SIZE}
                    domLayout={"autoHeight"}
                    groupDefaultExpanded={'1'}
                    enableRowGroup={false}
                    suppressDragLeaveHidesColumns={true}
                    suppressMakeColumnVisibleAfterUnGroup={true}
                    suppressModelUpdateAfterUpdateTransaction={true}
                    suppressScrollOnNewData={true}
                    suppressAggFuncInHeader={true}
                    allowShowChangeAfterFilter={false}
                    rowGroupPanelShow={'never'}
                    enableSorting={true}
                    enableFilter={true}
                    pagination={true}
                    defaultColDef={defaultColDef}
                    columnDefs={updatedColumnDefs}
                    rowData={rowData}
                    animateRows={true}
                    onGridReady={onGridReady}
                    onFirstDataRendered={onDataRendered}
                    onPaginationChanged={onPaginationChange}
                >
                </AgGridReact>
            </div>
        </Container>
        {showButton && <ScrollButton showButton={showButton}/>}

    </>);

}
