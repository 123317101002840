
export const addGridPropertiesToGridColumns = (gridColumnDefs, additionalProperties)=> {
    const updatedGridColumnDefs = new Map();
    gridColumnDefs.forEach((columns, columKey)=>{
        const updatedColumns = columns.map(columDef => {
            if(columDef.headerName !== 'Actions' && columDef.headerName!=='') {
                return   {...columDef,
                    ...additionalProperties}
            } else {
                return {...columDef,
                    ...additionalProperties,
                    filter: null
                }
            }

        });
        updatedGridColumnDefs.set(columKey, updatedColumns)
    })
    return updatedGridColumnDefs;
}

export const addPropertiesToGridColumns = (gridColumnDefsArray, additionalProperties)=> {
    const updatedColumns = gridColumnDefsArray?.map(columDef => {
        if(columDef.headerName !== 'Actions' && columDef.headerName!=='') {
            return   {...columDef,
        ...additionalProperties}
        } else {
            return {...columDef,
                ...additionalProperties,
                filter: null
            }
        }

    });
    return  updatedColumns;

}

export const updateGridColumDefs = (gridColumnApi, columnDefs)=>{
    const displayedColumns = gridColumnApi.getAllDisplayedColumns();
    const updatedColumnDefs = columnDefs.map((columnDef)=>{
        const isDisplayed = displayedColumns.find((col)=> col.getColId() === columnDef?.field);
        return   {...columnDef, hide: !isDisplayed}
    })
    return updatedColumnDefs;
}

export const getSelectedRows = (gridApi)=>{
    let selectedRows = [];
    if(gridApi){
        selectedRows = gridApi.getSelectedRows();
    }
    return selectedRows;
}

export const getDisplayedNumberOfGridRows = (gridApi) => {
    // https://github.com/ag-grid/ag-grid/issues/2654#issuecomment-440118430
    return gridApi?.rowRenderer?.getRenderedNodes()?.length || 0;
}

export const updateTradesRowData = (resData)=>{
    return resData?.map((row)=> {
        if(row?.commissionType === '3') {
            return {...row, commissionType: 'Total'}
        } else if(row?.commissionType === '1') {
            return {...row, commissionType: 'Rate'}
        } else {
            return row
        }
    })
}
