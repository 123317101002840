import React from "react";
import TradeBreakFilter from "./TradeBreakFilter";
import SidePanelLayout from "../../layouts/SidePanelLayout";
import TradeBreaksPanel from "./TradeBreaksPanel";


export default function TradeBreaks(){

    return(
        <SidePanelLayout
            sideComponent={TradeBreakFilter}
            mainComponent={TradeBreaksPanel}>
        </SidePanelLayout>
    );
}


