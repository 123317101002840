    import {createContext, useState, useContext} from "react";
import {
    numberFormat4FractionalDigits,
    numberFormatIntegerStr
} from "utils/NumberUtils";
    import {TRADES_LIST_TRADE_BREAKS} from "../../../service/UrlConstant";
    import {SecurityContext} from "../../../context/security";
    import "./TradeCaptureContext"
export const Context = createContext({});

export const Provider = props => {
    const {children} = props;
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [selectedBrkType, setSelectedBrkType] = useState();
    const [highlightTradeEntryFields, setHighlightTradeEntryFields] = useState({})
    const initTradeBreakFilterState = {
        security: null,
        breakType: null,
    }
    const initPopUpDataState = [
        {'field':'tradeDate','header':'Trade Date','client':'','broker':'', isBreak:false},
        {'field':'settleDate','header':'Settle Date','client':'','broker':'', isBreak:false},
        {'field':'security','header':'Security','client':'','broker':'', isBreak:false},
        {'field':'symbol','header':'Symbol','client':'','broker':'', isBreak:false},
        {'field':'brokerCode','header':'Broker Code','client':'','broker':'', isBreak:false},
        {'field':'tranType','header':'Tran Type','client':'','broker':'', isBreak:false},
        {'field':'price','header':'Price','client':'','broker':'', isBreak:false},
        {'field':'quantity','header':'Quantity','client':'','broker':'', isBreak:false},
        {'field':'net','header':'Net','client':'','broker':'', isBreak:false},
        {'field':'interest','header':'Interest','client':'','broker':'', isBreak:false},
        {'field':'commission','header':'Commission','client':'','broker':'', isBreak:false},
        {'field':'execBroker','header':'Exec Brk','client':'','broker':'', isBreak:false},
        {'field':'dtcControlNumber','header':'DTC Control Number','client':'','broker':'', isBreak:false},
        {'field':'reason','header':'Reason','client':'','broker':'', isBreak:false},
    ]
    const [selectedBreak, setSelectedBreak] = useState({});
    const [popUpData, setPopUpData] = useState(JSON.parse(JSON.stringify(initPopUpDataState)));
    const [viewClicked, setViewClicked] = useState(false);
    const [gridApi, setGridApi] = useState(null);
    const [rowData, setRowData] = useState([]);
    const [gridColumnApi,setGridColumnApi] = useState(null);
    const [tradeBreakFilterState, setTradeBreakFilterState] = useState(JSON.parse(JSON.stringify(initTradeBreakFilterState)));

    const tradeBreakTypeHashmap = {
        "BRKS":"Client Only",
        "UNCN":"Broker Only",
        "MTPT":"Match with Differences",
        "MTCH":"Matches",
        "CACN":"Cancels"
    }
    const fieldToReasonColMapping = {
        'quantity': 'NetQuantity',
        'net': 'NetAmount',
        'execbroker': 'Executing Broker',
        'price':'Price',
        'settledate':'Settlement Date',
        'commission':'Commission',
        'interest':'Interest'
    }

    const getReasonEquivalentOfField =(field)=> {
        if(!!field)
            field = field.replace('broker','').replace('client','').toLowerCase()
        return fieldToReasonColMapping[field];
    }

    const mapTradeBreakType = (param) => {
        if(!!param)
            return tradeBreakTypeHashmap[param.data.breakType]
        return ''
    }

    const clientOrBrokerData = (param) => {
        if(!!param){
            if(param.data){
                let returnValue
                Object.entries(param?.data).map(([key, value]) => {
                    if(key.replace('client','').toLowerCase()===param.colDef.field.toLowerCase()){
                        returnValue = value
                        return value;
                    }
                    if(key.replace('broker','').toLowerCase()===param.colDef.field.toLowerCase()){
                        returnValue = value
                        return value;
                    }
                })
                return returnValue
            }
        }
        return ''
    }

    const accountCodeMapping = (param) =>{
        if(!!param)
            return param.data.breakType === 'UNCN'? param.data.brokerAccountCode : param.data.clientAccountCode;
        return ''
    }

    const cellStyleChecker=(param)=>{
        let columnName = param.colDef.field;
        let reason = param.data.clientReason;
        if (checkColumnInReason(columnName,reason)) return {backgroundColor: '#F7E8E9', color:'#AE1100'}
        return
    }

    const numericCellStyleChecker=(param)=>{
        const numericStyle = {textAlign: 'right'};
        return {...numericStyle,...cellStyleChecker(param)}
    }

    const checkColumnInReason=(columnName,reason)=>{
        if(!!reason && !!columnName && reason.includes(getReasonEquivalentOfField(columnName)))
            return true;
        return false
    }

    const {
        makeGetRequest
    } = useContext(SecurityContext);

    const loadTradeBreaksList = () => {
        const onSuccess = (response) => {
            if(!!response.data && response.data.length > 0){
                setRowData([...response.data]);
            }
            gridColumnApi.resetColumnState();
        }
        const onError = (err) => {
            console.error("Trade Breaks data",err);
        }

        makeGetRequest(TRADES_LIST_TRADE_BREAKS, {}, onSuccess, onError);
    };

    const loadTradeBreaksListWithParams = (entity, account, security, breakType) => {
        const onSuccess = (response) => {
            if(!!response.data && response.data.length > 0){
                setRowData([...response.data]);
            }
            gridColumnApi.resetColumnState();
        }
        const onError = (err) => {
            console.error("Trade Breaks data",err);
        }

        makeGetRequest(TRADES_LIST_TRADE_BREAKS, {entity: entity, account: account, security: security, breakType: breakType
        }, onSuccess, onError);
    };

    const columnDefsTradeBreaks = [
        {headerName: 'id', field: 'id', hide: true, suppressColumnsToolPanel:true},
        {headerName: 'Break Type', field: 'breakType', width:'185', valueGetter:mapTradeBreakType, cellRenderer: 'agGroupCellRenderer'},
        {headerName: 'Entity', field: 'shortName', width:'100'},
        {headerName: 'Account', field: 'accountCode', width:'105', valueGetter:accountCodeMapping},
        {headerName: 'Security', field: 'security', width:'105'},
        {headerName: 'CCY', field: 'tradeCcy', width:'105'},
        {headerName: 'Front Exec', field: 'brokerCode', width:'105'},

        //I am keeping these columns hidden so that the export function shows the same data it did previously
        {headerName: 'Trade Date', field: 'clientTradeDate', hide: true},
        {headerName: 'Settle Date', field: 'clientSettleDate', hide: true},
        {headerName: 'Tran Type', field: 'clientTranType', hide: true},
        {headerName: 'Price', field: 'clientPrice', hide: true, cellStyle:numericCellStyleChecker},
        {headerName: 'Quantity', field: 'clientQuantity', hide: true, valueFormatter: numberFormatIntegerStr},
        {headerName: 'Net', field: 'clientNet',hide: true, valueFormatter: params => params.value ? numberFormat4FractionalDigits(params): ''},
        {headerName: 'Interest', field: 'clientInterest', hide: true},
        {headerName: 'Commission', field: 'clientCommission', hide: true},
        {headerName: 'Exec Brk', field: 'clientExecBroker', hide: true},

        {headerName: 'Trade Date', field: 'brokerTradeDate',hide: true},
        {headerName: 'Settle Date', field: 'brokerSettleDate',hide: true},
        {headerName: 'Tran Type', field: 'brokerTranType',hide: true},
        {headerName: 'Price', field: 'brokerPrice',hide: true},
        {headerName: 'Quantity', field: 'brokerQuantity',hide: true, valueFormatter: numberFormatIntegerStr},
        {headerName: 'Net', field: 'brokerNet', hide: true, valueFormatter: params => params.value ? numberFormat4FractionalDigits(params): ''},
        {headerName: 'Interest', field: 'brokerInterest',hide: true},
        {headerName: 'Commission', field: 'brokerCommission',hide: true},
        {headerName: 'Exec Brk', field: 'brokerExecBroker',hide: true},
        {headerName: 'DTC Control Number', field: 'brokerDtcControlNumber', hide: true},
        {headerName: 'Reason', field: 'brokerReason', hide: true},

        // {headerName: '', field: 'brokerCode', flex: 1},
    ];

    const detailCellRendererParams = () => {
        return {
            detailGridOptions: {
                columnDefs:[
                    {headerName: '', field: 'type', width:80},
                    {headerName: 'Trade Date', field: 'TradeDate', valueGetter: clientOrBrokerData,width:115},
                    {headerName: 'Settle Date', field: 'SettleDate', valueGetter: clientOrBrokerData, width:115},
                    {headerName: 'Tran Type', field: 'TranType', valueGetter: clientOrBrokerData, width:110},
                    {headerName: 'Price', field: 'Price', valueGetter: clientOrBrokerData,width:95},
                    {headerName: 'Quantity', field: 'Quantity', valueGetter: clientOrBrokerData, width:105, valueFormatter: numberFormatIntegerStr},
                    {headerName: 'Net', field: 'Net', valueGetter: clientOrBrokerData,width:130, valueFormatter: params => params.value ? numberFormat4FractionalDigits(params): ''},
                    {headerName: 'Interest', field: 'Interest', valueGetter: clientOrBrokerData, width:100, cellStyle:numericCellStyleChecker},
                    {headerName: 'Commission', field: 'Commission', valueGetter: clientOrBrokerData, width:125, cellStyle:numericCellStyleChecker},
                    {headerName: 'Exec Brk', field: 'ExecBroker', valueGetter: clientOrBrokerData, width:100},
                    {headerName: 'DTC Control Number', field: 'brokerDtcControlNumber', width:170},
                    {headerName: 'Reason', field: 'brokerReason', width:400},
                    {headerName: '', flex: 1},
                ],
                onGridReady: function(params) {
                    params.api.setDomLayout('autoHeight');
                },
            },
            getDetailRowData: (params) => {
                let newerExpandedRows = []
                const brokerData = {"type": "Broker"};
                const clientData = {"type": "Client"};

                Object.entries(params.data).forEach(([key, value]) => {
                    if (key.startsWith('client')) {
                        clientData[key] = value;
                    }
                    if (key.startsWith('broker')) {
                        brokerData[key] = value;
                    }
                });
                if (clientData.clientTradeDate !==null && clientData.clientSettleDate !==null) newerExpandedRows.push(clientData)
                if (brokerData.brokerTradeDate !==null && brokerData.brokerSettleDate !==null) newerExpandedRows.push(brokerData)
                params.successCallback([...newerExpandedRows]);
            },
            template: '<Row ref="eDetailGrid" className="detail-grid"></Row>'
        };
    };

    const tradeBreakContext = {
        popUpData,
        setPopUpData,
        showInfoModal,
        setShowInfoModal,
        tradeBreakTypeHashmap,
        columnDefsTradeBreaks,
        selectedBrkType,
        setSelectedBrkType,
        selectedBreak,
        mapTradeBreakType,
        accountCodeMapping,
        cellStyleChecker,
        getReasonEquivalentOfField,
        setSelectedBreak,
        highlightTradeEntryFields,
        setHighlightTradeEntryFields,
        viewClicked, setViewClicked,
        loadTradeBreaksList,
        gridApi, setGridApi, rowData, setRowData, gridColumnApi,setGridColumnApi, loadTradeBreaksListWithParams,
        initTradeBreakFilterState, tradeBreakFilterState, setTradeBreakFilterState,
        detailCellRendererParams
    }

    return <Context.Provider value={tradeBreakContext}>{children}</Context.Provider>

};

export const {Consumer} = Context;
