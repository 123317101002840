import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {AgGridReact} from "ag-grid-react";
import useWindowDimensions from "hooks/Dimensions";
import {TradeBreakContext} from "./context";
import {DEFAULT_GRID_PAGINATION_SIZE, ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE, TRADE_BREAK_STATE} from "utils/Constants";
import InfoLayoutTradeBreak from "./InfoLayoutTradeBreak";
import AgGridToolbar from "components/AgGridToolbar/AgGridToolbar";
import {createExcelExportParams} from "../../utils/ExcelExportUtils";
import {UtilsContext} from "../../context/utils";
import {ActionPanelContext} from "../../context/action";
import ScrollButton from "../../components/AgGridToolbar/ToolBarButtons/ScrollButton";
import {getDisplayedNumberOfGridRows} from "../../utils/gridColumnUtils";
import {getSessionAttribute, setSessionAttribute} from "../../utils/SessionUtils";


export default function TradeBreaksPanel(){
    const {popUpData, setPopUpData, highlightTradeEntryFields, setHighlightTradeEntryFields, selectedBrkType, setSelectedBrkType, tradeBreakTypeHashmap, accountCodeMapping, columnDefsTradeBreaks, showInfoModal, setShowInfoModal,getReasonEquivalentOfField, cellStyleChecker, setSelectedBreak,
        loadTradeBreaksList, gridApi, setGridApi, rowData, gridColumnApi,setGridColumnApi, detailCellRendererParams} = useContext(TradeBreakContext);
    const gridRef = useRef();
    const [columnDefs, setColumnDefs] = useState([]);

    const myGrid = useRef(null);
    const [actionForAccount, setActionForAccount] = useState('');
    const {isMobile} = useWindowDimensions();
    const {hiddenExportGridApi} = useContext(UtilsContext)
    const {setHandleRefreshFunction, setHandleExportFunction} = useContext(ActionPanelContext);

    const {paginationPageSize, showButton, setShowButton,setPaginationPageSize} = useContext(UtilsContext);



    useEffect(() => {
        setHandleRefreshFunction({handleRefresh: loadTradeBreaksList, gridApi:null });
        setHandleExportFunction({handleExport:exportTradebreakList, gridApi: gridApi});
    }, [rowData])

    useEffect(() => {
        setupActionColumns();
        loadTradeBreaksList()
    }, [])

    const getFilteredColumnsWithHiddenColumns = (grid) => {
        let filteredColumns = (grid && grid.current.columnApi )?
            [...grid.current.columnApi.getAllColumns()]: [];
        return filteredColumns.length === 0 ? [] : filteredColumns.map(item => {
            return item.getColId()
        });
    }

    const exportTradebreakList = (type, rowData, gridApi, onSuccess, onError) => {
        //Set Column definition
        let columnDefsToExport = columnDefs.filter(e=>!e.suppressColumnsToolPanel)
        hiddenExportGridApi.current.api.setColumnDefs(columnDefsToExport);
        //Set Data
        hiddenExportGridApi.current.api.setRowData(rowData);
        let filteredColumnNames = getFilteredColumnsWithHiddenColumns(gridRef);
        //Export as csv/excel
        type==='EXCEL' ?
            hiddenExportGridApi.current.api.exportDataAsExcel(createExcelExportParams('TradeBreaks', filteredColumnNames)) :
            hiddenExportGridApi.current.api.exportDataAsCsv({ columnKeys: filteredColumnNames})
    }

    const setupActionColumns = () => {
        let updatedColumnDefs = [...columnDefsTradeBreaks];
        if(updatedColumnDefs.headerName !== ''){
            updatedColumnDefs.push({headerName: '', cellRenderer: 'viewCellRenderer', suppressColumnsToolPanel:true, flex: 1, type: 'rightAligned', floatingFilter: false,cellRendererParams: {
                    clicked: (props) => onInfoClicked(props),
                }});
        }

        setColumnDefs(updatedColumnDefs);
    }

    const defaultColDef = {
        sortable:true,
        resizable:true,
        enableCellChangeFlash:true,
        lockPinned:isMobile?true:false,
        width:'auto',
        cellStyle: cellStyleChecker,
    };

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        params.columnApi.setColumnState(getSessionAttribute(TRADE_BREAK_STATE));
    }


    const onInfoClicked = (field) => {
        const selectedBreak = {};
        selectedBreak['id'] = field.data.id;
        selectedBreak['managerId'] = field.data.managerId;
        selectedBreak['omniQuantity'] = parseInt(field.data.clientQuantity !== null ? field.data.clientQuantity.replace(/,/g,'') : field.data.brokerQuantity.replace(/,/g,''));
        selectedBreak['omniCommission'] = field.data.clientCommission ?? field.data.brokerCommission;
        setSelectedBreak(selectedBreak);
        const popUpDataState = JSON.parse(JSON.stringify(popUpData));
        const highlight= JSON.parse(JSON.stringify(highlightTradeEntryFields));
        setShowInfoModal(true);
        setSelectedBrkType('Break Type : '+tradeBreakTypeHashmap[field.data.breakType]);
        Object.entries(field.data).map(([key, value]) => {
            popUpDataState.map(e=>{
                if(key.replace('client','').toLowerCase()===e.field.toLowerCase()){
                    e.client = (field.data.breakType === 'BRKS' || field.data.breakType === 'MTPT')? value : null;
                    e.isBreak = field.data.clientReason.includes(getReasonEquivalentOfField(e.field))?true:false
                    highlight[e.field] = e.isBreak;
                }
                if(key.replace('broker','').toLowerCase()===e.field.toLowerCase()){
                    e.broker = (field.data.breakType === 'UNCN' || field.data.breakType === 'MTPT')? value : null;
                    e.isBreak = field.data.brokerReason.includes(getReasonEquivalentOfField(e.field))?true:false
                }
            })
        })
        setHighlightTradeEntryFields({...highlight})
        setPopUpData(popUpDataState)
        setActionForAccount(accountCodeMapping(field));
    }


    const viewCellRenderer = function (props){
        return (<>
            {(tradeBreakTypeHashmap[props.data['breakType']] === 'Match with Differences' || tradeBreakTypeHashmap[props.data['breakType']] === 'Client Only' || tradeBreakTypeHashmap[props.data['breakType']] === 'Broker Only')  &&
                <a className="td-link" href="#" onClick={() => {
                    //onViewClicked(props.data);
                    onInfoClicked(props)
                }}>View</a>
            }
        </>);
    }

    const rowClassRules = useMemo(() => {
        return {
            'grey-out': (params) => {
                return params.data.isCorrected>0;
            },

        };
    }, []);

    const sizeToFit = useCallback(() => {
        gridRef.current.api.setColumnDefs(gridRef.current.api.getColumnDefs().filter(e=>e.field!=='flex'))
        gridRef.current.api.sizeColumnsToFit({
            defaultMinWidth: 100,
            // columnLimits: [{ key: 'flex',flex:-1 }],
        });
    }, []);
    const onFirstDataRendered = (params) => {
        const pageToNavigate = JSON.parse(getSessionAttribute(TRADE_BREAK_STATE));
        params.api.paginationGoToPage(pageToNavigate);
        if (gridApi) setShowButton(getDisplayedNumberOfGridRows(gridApi) >= ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE);
    }
    const onPaginationChange = (params) => {
        let currentPage = params.api.paginationGetCurrentPage();
        if (params.newPage && params.api.paginationGetTotalPages() !== 0) {
            setSessionAttribute(TRADE_BREAK_STATE, JSON.stringify(currentPage));
        }
        if (gridApi) setShowButton(getDisplayedNumberOfGridRows(gridApi) >= ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE);
    }
    const onStateUpdated = (params) => {
        console.log("State Updated: ", gridApi.getState())
        setSessionAttribute(TRADE_BREAK_STATE, gridApi.getState());
    }

    const autoGroupColumnDef = () => {
        return {
            headerName: "",
            minWidth: 3,
            cellRendererParams: {
                suppressCount: true,
            },
        };
    };

    const getRowHeight = params => {
        const isDetailRow = params.node?.master;
        if (!isDetailRow) {
            const detailPanelHeight = params.data.breakType === "BRKS" || params.data.breakType === "UNCN" ?
                (83): (115);
            if (params.node.lastChild){
                gridApi.refreshCells()
            }
            return detailPanelHeight;
        }
        return undefined;
    };

    function currentComponent() {

        return (<>

            <Container fluid style={{ height: '100%' }} className={"break-grid "} >

                <Row className="pt-3 pb-2 pt-md-4 pb-md-3">
                    <Col>
                        <h1>Trade Breaks</h1>
                    </Col>
                </Row>
                {<AgGridToolbar

                    tradeBreak={true}
                    gridApi={gridApi}
                    gridColumnApi={gridColumnApi}
                    sizeToFit = {sizeToFit}
                    columnDefs = {columnDefs}
                    setColumnDefs = {setColumnDefs}
                    rowData={rowData}
                    paginationPageSize={paginationPageSize}
                    setPaginationPageSize={setPaginationPageSize}
                >
                </AgGridToolbar>}
                <div
                    style={{ height: '100%' }}
                    ref={myGrid}
                    id="myGrid"
                    className="ag-theme-alpine">
                    <AgGridReact
                        ref={gridRef}
                        pagination={true}
                        paginationPageSize={DEFAULT_GRID_PAGINATION_SIZE}
                        frameworkComponents={{
                            viewCellRenderer:viewCellRenderer,
                        }}
                        domLayout={"autoHeight"}
                        enableRowGroup={false}
                        suppressDragLeaveHidesColumns={true}
                        suppressMakeColumnVisibleAfterUnGroup={true}
                        suppressModelUpdateAfterUpdateTransaction={true}
                        suppressScrollOnNewData={true}
                        suppressAggFuncInHeader={true}
                        allowShowChangeAfterFilter={false}
                        rowGroupPanelShow={'never'}
                        enableSorting={true}
                        onGridReady={onGridReady}
                        defaultColDef={defaultColDef}
                        columnDefs={columnDefs}
                        rowData={rowData}
                        rowClassRules={rowClassRules}
                        onFirstDataRendered={onFirstDataRendered}
                        onRowDataChanged={onFirstDataRendered}
                        onPaginationChanged={onPaginationChange}
                        stateUpdated={onStateUpdated}
                        autoGroupColumnDef={autoGroupColumnDef}
                        masterDetail={true}
                        getRowHeight={getRowHeight}
                        detailCellRendererParams={detailCellRendererParams}
                    >
                    </AgGridReact>
                </div>
            </Container>
            {showButton && <ScrollButton showButton={showButton}/>}
            <InfoLayoutTradeBreak show={showInfoModal} setShow={setShowInfoModal} popUpData={popUpData} actionForAccount={actionForAccount} secondTitle={selectedBrkType}/>
        </>);
    }

    return(<>
        <Container fluid>
            <Row>
                <Col style={{padding:0}}>
                    {currentComponent()}
                </Col>
            </Row>
            <Row>
                <br/>
            </Row>
        </Container>
    </>);
}


