import React, {useContext, useEffect, useState} from "react";
import {Col, Container, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import {TradeBreakContext, TradeCaptureContext} from "./context";
import {EntityAccountsContext} from "components/entity-accounts/context";
import {EntityAccountsComponent} from "components/entity-accounts/EntityAccountsComponent";
import {FilterContext} from "layouts/SidePanelLayout";
import {updateTradesFilterFormErrors} from "../../utils/ErrorUtils";
import {getSessionAttribute} from "../../utils/SessionUtils";
import {ACCOUNTS_OPTIONS} from "../../utils/Constants";

export default function TradeBreakFilter() {

    const {
        tradePanelFilterState,
        clearTradeSearchAndLoadTradingHome,
        setHeaderCheckedboxChecked,
        setSelectedRows,
        setSelectedAnyCheckbox
    } = useContext(TradeCaptureContext);
    const {loadTradeBreaksListWithParams, tradeBreakFilterState, setTradeBreakFilterState} = useContext(TradeBreakContext);
    const {entityAccounts, clearEntityAccounts, populateDefaultEntity, entityAccountsOptions} = useContext(EntityAccountsContext);

    const [formErrors, setFormErrors] = useState({});
    const [accountOptional, setAccountOptional] = useState(true);
    const [entityOptional, setEntityOptional] = useState(true);
    const {showFilter, setShowFilter} = useContext(FilterContext);

    const handleSearchOnChange = (e) => {
        let tradeBreakFilterStateUpdated = JSON.parse(JSON.stringify(tradeBreakFilterState));
        const targetName = e.currentTarget.name;
        const targetValue = e.currentTarget.value;
        tradeBreakFilterStateUpdated[targetName] = targetValue;
        setTradeBreakFilterState(tradeBreakFilterStateUpdated);

        updateTradesFilterFormErrors(targetName, targetValue,
            formErrors, setFormErrors, tradePanelFilterState);
    }

    useEffect(() => {
        populateDefaultEntity(true)
    }, [entityAccountsOptions])

    const handleSearchClear = (e) => {
        clearEntityAccounts(true, true);
        clearTradeSearchAndLoadTradingHome();
        setAccountOptional(true)
        setEntityOptional(true)
    }

    const handleSearchClick = (e) => {
        e.preventDefault();

        const correspondingEntity = getSessionAttribute(ACCOUNTS_OPTIONS).find((entity) => entity.managerId === entityAccounts.managerId);

        loadTradeBreaksListWithParams(correspondingEntity?.shortName, entityAccounts.accountCode, tradeBreakFilterState.security, tradeBreakFilterState.breakType && tradeBreakFilterState.breakType==="ALL" ? null: tradeBreakFilterState.breakType);

        setShowFilter(!showFilter)

        setHeaderCheckedboxChecked(false);
        setSelectedAnyCheckbox(false)
        setSelectedRows([]);
    };


    return (<>
        <Container fluid className="mt-md-3 mt-sm-0">
            <Form>
                <h2 className="d-none d-md-block">Search</h2>
                <Row>
                    <EntityAccountsComponent accountOptional={accountOptional} entityOptional={entityOptional}/>
                    <Col xs={12} sm={12} md={12} className={'py-0'}>
                        <Form.Group controlId="formGridSecurity">
                            <label className='my-0'>Security (Optional)</label>
                            <InputGroup>
                                <FormControl className='pl-3'
                                             placeholder="Name or Symbol" name="security"
                                             value={tradeBreakFilterState.security}
                                             onChange={handleSearchOnChange}
                                />
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col md={12}>
                        <Form.Group controlId="formGridStatus" className={'td-select'} >
                            <Form.Label className="filter-input-label">Break Type</Form.Label>
                            <Form.Control as="select" name="breakType"
                                          value={tradeBreakFilterState.breakType}
                                          onChange={handleSearchOnChange}
                                          isInvalid={!!formErrors.status}>
                                <option value='ALL'>ALL</option>
                                <option value='UNCN'>Broker Only</option>
                                <option value='BRKS'>Client Only</option>
                                <option value='MTPT'>Match With Differences</option>
                            </Form.Control>
                            <Form.Control.Feedback type={"invalid"}><b><i className="bi bi-exclamation-circle"></i>{formErrors.status}</b></Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col md={12}>
                        <button className="btn btn-block td-btn-primary-light my-3" href="#"
                                onClick={handleSearchClick}>Refresh
                        </button>
                    </Col>
                    <Col md={12}>
                        <button className="btn btn-block td-btn-secondary-clear my-3"
                                href="#" onClick={handleSearchClear}>Clear
                        </button>
                    </Col>
                </Row>
            </Form>
        </Container>
    </>);

}
