import {
    cellAlignment,
    cellStyleChecker,
    numberFormat3FractionalDigits, numberFormat4FractionalDigitsPer, numberFormat4FractionalDigits,
    numberFormat5FractionalDigits,
    numberFormatter, numberFormat3FractionalDigitsStr
} from "../../utils/NumberUtils";
import {
    dateToString,
    formatDateStringToDate,
    formatDateString,
    stringToDate
} from "../../utils/FormatUtils";
import {GRID_COLUMN_PROPERTY} from '../../utils/Constants';
import {addGridPropertiesToGridColumns} from '../../utils/gridColumnUtils'

export const getColDef = (usePortalColumns,queryId, specialFlag = 0) => {
    let queryGridDataColumnDefs;
    if (specialFlag === 1)
        queryId+= '-Special'
    if (usePortalColumns){
        // queryGridDataColumnDefs = appendFilter(queryGridDataColumnDefsPortal, {filter: 'agTextColumnFilter', floatingFilter: true});
        queryGridDataColumnDefs = addGridPropertiesToGridColumns(queryGridDataColumnDefsPortal, GRID_COLUMN_PROPERTY)
        return queryGridDataColumnDefs.get(queryId)}
    else{
        // queryGridDataColumnDefs = appendFilter(queryGridDataColumnDefs, {filter: 'agTextColumnFilter', floatingFilter: true});
        queryGridDataColumnDefs = addGridPropertiesToGridColumns(queryGridDataColumnDefs, GRID_COLUMN_PROPERTY)
        return queryGridDataColumnDefs.get(queryId)}
}

export const queryGridDataColumnDefs = new Map([
    ['Account Information', [
        {headerName: 'Key', field: 'Key_10'},
        {headerName: 'Value', field: 'Value_80'},
        {headerName: 'Comments', field: 'Comments_1'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ], {isAggEnabled: false}],
    ['Cash Balances', [
        {headerName: 'CCY', field: 'CCY_3'},
        {headerName: 'Type', field: 'Type_2'},
        {headerName: 'TD Balance', field: 'mnyTDBalance_8', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'SD Balance', field: 'mnySDBalance_8', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'FX(USD)', field: 'fxFx(USD)_4', valueFormatter: numberFormat5FractionalDigits,cellStyle: cellAlignment, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'TD Balance(USD)', field: 'mnyTDBalance(USD)_8', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'SD Balance(USD)', field: 'mnySDBalance(USD)_8', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Effective Date', field: 'Effective Date_7', valueGetter: (params)=>stringToDate(dateToString(params.data['Effective Date_7']))},
        {headerName: 'Account', field: 'Account_4'},
        {headerName: 'Comments', field: 'Comments_1'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['Cash Flow Detail', [
        {headerName: 'CCY', field: 'CCY_3'},
        {headerName: 'Activity Type', field: 'Activity Type_4'},
        {headerName: 'Trade Date', field: 'Trade Date_4', valueGetter: (params)=>stringToDate(dateToString(params.data['Trade Date_4']))},
        {headerName: 'Settle Date', field: 'Settle Date_4', valueGetter: (params)=>stringToDate(dateToString(params.data['Settle Date_4']))},
        {headerName: 'Entry Date', field: 'Entry Date_4', valueGetter: (params)=>stringToDate(dateToString(params.data['Entry Date_4']))},
        {headerName: 'Activity', field: 'Activity_5'},
        {headerName: 'Security Description', field: 'Security Description_15'},
        {headerName: 'Security', field: 'Security_5'},
        {headerName: 'TDBalance', field: 'mnyTDBalance_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Quantity', field: 'shsQuantity_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Amount', field: 'mnyAmount_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Total Run', field: 'mnyRunTotal_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Price', field: 'pxPrice_5', valueFormatter: numberFormat3FractionalDigits,cellStyle: cellAlignment, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'COMM', field: 'mnyComm_4', valueFormatter: numberFormat3FractionalDigits, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Fee', field: 'mnyFee_4', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'TcktChg', field: 'mnyTcktChg_4', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Trailer', field: 'Trailer_5'},
        {headerName: 'Tran', field: 'Tran_3'},
        {headerName: 'FX(USD)', field: 'fxFx(USD)_4', valueFormatter:numberFormat5FractionalDigits,cellStyle: cellAlignment, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'Account', field: 'Account_4'},
        {headerName: 'Comments', field: 'Comments_1'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['Cash Flow Summary', [
        {headerName: 'CCY', field: 'CCY_3'},
        {headerName: 'Type', field: 'Type_2'},
        {headerName: 'Activity Type', field: 'Activity Type_4'},
        {headerName: 'Balance Date', field: 'Balance Date_6', valueGetter: (params)=> params.data['Balance Date_6'] === "        " || params.data['Balance Date_6'] ==='' || params.data['Balance Date_6'] === null ? '' : stringToDate(dateToString(params.data['Balance Date_6'])), isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Activity', field: 'Activity_8'},
        {headerName: 'TD Balance', field: 'mnyTDBalance_8', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'Amount', field: 'mnyAmount_8', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Account', field: 'Account_4'},
        {headerName: 'Comments', field: 'Comments_1'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['Commissions', [
        {headerName: 'Class', field: 'Class_3'},
        {headerName: 'CCY', field: 'CCY_2'},
        {headerName: 'BS', field: 'BS_2'},
        {headerName: 'SS', field: 'SS_2'},
        {headerName: 'OC', field: 'OC_2'},
        {headerName: 'Symbol', field: 'Symbol_5'},
        {headerName: 'Security Description', field: 'Security Description_12'},
        {headerName: 'Quantity', field: 'shsQuantity_5', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Trade Date', field: 'TradeDate_4', valueGetter: (params)=>stringToDate(dateToString(params.data['TradeDate_4']))},
        {headerName: 'COMM', field: 'mnyComm_5', valueFormatter: numberFormat3FractionalDigits, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Exec Brk', field: 'Exec Brk_4'},
        {headerName: 'Contra', field: 'Contra_6'},
        {headerName: 'Exchange', field: 'Exchange_4'},
        {headerName: 'Tran', field: 'Tran_4'},
        {headerName: 'Entry Date', field: 'EntryDate_4', valueGetter: (params)=>stringToDate(dateToString(params.data['EntryDate_4']))},
        {headerName: 'Account', field: 'Account_4'},
        {headerName: 'Comments', field: 'Comments_1'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['Dividends Paid', [
        {headerName: 'CCY', field: 'CCY_3'},
        {headerName: 'Type', field: 'Type_2'},
        {headerName: 'Trade Date', field: 'Trade Date_4', valueGetter: (params)=>stringToDate(dateToString(params.data['Trade Date_4']))},
        {headerName: 'Entry Date', field: 'Entry Date_4', valueGetter: (params)=>stringToDate(dateToString(params.data['Entry Date_4']))},
        {headerName: 'Activity', field: 'Activity_8'},
        {headerName: 'Security Description', field: 'Security Description_12'},
        {headerName: 'Security', field: 'Security_5'},
        {headerName: 'Amount', field: 'mnyAmount_5', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Trailer', field: 'Trailer_10'},
        {headerName: 'Tran', field: 'Tran_4'},
        {headerName: 'FX(USD)', field: 'fxFx(USD)_5', valueFormatter: numberFormat5FractionalDigits,cellStyle: cellAlignment, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'Account', field: 'Account_4'},
        {headerName: 'Comments', field: 'Comments_1'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['Fails - Open', [
        {headerName: 'Class', field: 'Class_3'},
        {headerName: 'CCY', field: 'CCY_3'},
        {headerName: 'Contra', field: 'Contra_4'},
        {headerName: 'Cage DTC', field: 'Contra DTC_4'},
        {headerName: 'Contra Name', field: 'Contra Name_8'},
        {headerName: 'Security', field: 'Security_5'},
        {headerName: 'Security Description', field: 'Security Description_12'},
        {headerName: 'Entry Date', field: 'Entry Date_4', valueGetter: (params)=>stringToDate(dateToString(params.data['Entry Date_4']))},
        {headerName: 'Cusip', field: 'Cusip'},
        {headerName: 'ISIN', field: 'ISIN'},
        {headerName: 'Sedol', field: 'Sedol'},
        {headerName: 'Symbol', field: 'Symbol'},
        {headerName: 'Trade Date', field: 'Trade Date_4', valueGetter: (params)=>stringToDate(dateToString(params.data['Trade Date_4']))},
        {headerName: 'Settle Date', field: 'Settle Date_4', valueGetter: (params)=>stringToDate(dateToString(params.data['Settle Date_4']))},
        {headerName: 'B/S', field: 'B/S_2'},
        {headerName: 'Quantity', field: 'shsQuantity_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'Amount', field: 'mnyAmount_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Price', field: 'Price', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Original Quantity', field: 'shsQuantityOrig_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'Original Amount', field: 'mnyAmountOrig_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Tran', field: 'Tran_4'},
        {headerName: 'Status', field: 'Status_3'},
        {headerName: 'Account', field: 'Account_4'},
        {headerName: 'Effective', field: 'Comments_1'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['Interest Accruals', [
        {headerName: 'CCY', field: 'CCY_3'},
        {headerName: 'Type', field: 'Type_2'},
        {headerName: 'AccrualDt', field: 'AccrualDt_4', valueGetter: (params)=>stringToDate(dateToString(params.data['AccrualDt_4']))},
        {headerName: 'Description', field: 'Description_10'},
        {headerName: 'Balance', field: 'mnyBalance_10', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'BenchMark', field: 'BenchMark_4'},
        {headerName: 'BMarkRt', field: 'rtBMarkRt_5', cellStyle: cellStyleChecker, valueFormatter: numberFormat4FractionalDigitsPer, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'Spread', field: 'rtSpread_5', cellStyle: cellStyleChecker, valueFormatter: numberFormat4FractionalDigitsPer, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'Rate', field: 'rtRate_5', cellStyle: cellStyleChecker, valueFormatter: numberFormat4FractionalDigitsPer, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'Accrual', field: 'mnyAccrual_6', valueFormatter: numberFormat3FractionalDigits, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'MTDAccrual', field: 'mnyMTDAccrual_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'DayCt', field: 'shsDayCt_5', cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Acct', field: 'Acct_5'},
        {headerName: 'Comments', field: 'Comments_1'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['Journals', [
        {headerName: 'CCY', field: 'CCY_2'},
        {headerName: 'Type', field: 'Type_2'},
        {headerName: 'Mvmt', field: 'Mvmt_3'},
        {headerName: 'EntryDate', field: 'EntryDate_4', valueGetter: (params)=>stringToDate(dateToString(params.data['EntryDate_4']))},
        {headerName: 'Received', field: 'shsReceived_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Delivered', field: 'shsDelivered_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'CashIn', field: 'mnyCashIn_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'CashOut', field: 'mnyCashOut_5', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Security', field: 'Security_6'},
        {headerName: 'SecurityDescription', field: 'SecurityDescription_12'},
        {headerName: 'Action', field: 'Action_10'},
        {headerName: 'Source', field: 'Source_5'},
        {headerName: 'Account', field: 'Account_4'},
        {headerName: 'Comments', field: 'Comments_1'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['Journals With Symbol', [
        {headerName: 'CCY', field: 'CCY_2'},
        {headerName: 'Type', field: 'Type_2'},
        {headerName: 'Mvmt', field: 'Mvmt_3'},
        {headerName: 'EntryDate', field: 'EntryDate_4', valueGetter: (params)=>stringToDate(dateToString(params.data['EntryDate_4']))},
        {headerName: 'Received', field: 'shsReceived_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Delivered', field: 'shsDelivered_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'CashIn', field: 'mnyCashIn_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'CashOut', field: 'mnyCashOut_5', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Symbol', field: 'Symbol_3'},
        {headerName: 'Security', field: 'Security_7'},
        {headerName: 'SecurityDescription', field: 'SecurityDescription_12'},
        {headerName: 'Action', field: 'Action_10'},
        {headerName: 'Source', field: 'Source_5'},
        {headerName: 'Account', field: 'Account_4'},
        {headerName: 'Comments', field: 'Comments_1'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['LegacyPosRpt', [
        {headerName: 'Class', field: 'Class_3'},
        {headerName: 'Type', field: 'Type_3'},
        {headerName: 'CCY', field: 'CCY_3'},
        {headerName: 'Symbol', field: 'Symbol_4'},
        {headerName: 'Security Description', field: 'Security Description_12'},
        {headerName: 'TradeDate', field: 'shsTradeDate_5', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'TradeDateValue', field: 'mnyTradeDateValue_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'SettleDate', field: 'shsSettleDate_5', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Price', field: 'pxPrice_4', valueFormatter: numberFormat3FractionalDigits, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'InMny', field: 'mnyInMny_4', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        //{headerName: 'PX CCY', field: 'CCY(Px)_3'}, VIPER doesn't support this
        {headerName: 'Activity', field: 'shsActivity_4', cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'TrdDtOpen', field: 'shsTrdDtOpen_5',valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Pending', field: 'shsPending_4', cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Dom', field: 'Dom_3'},
        {headerName: 'Security', field: 'Security_5'},
        {headerName: 'PrxSrc', field: 'PrxSrc_5'},
        {headerName: 'Account', field: 'Account_4'},
        {headerName: 'Comments', field: 'Comments_1'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['Margin Requirements', [
        {headerName: 'Class', field: 'Class_2'},
        {headerName: 'Type', field: 'Type_3'},
        {headerName: 'Strategy', field: 'Strategy_3'},
        {headerName: 'LS', field: 'LS_2'},
        {headerName: 'CCY', field: 'CCY_2'},
        {headerName: 'Symbol', field: 'Symbol_4'},
        {headerName: 'Security Description', field: 'Security Description_12'},
        {headerName: 'Quantity', field: 'shsQuantity_5', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'Price', field: 'pxPrice_4', valueFormatter: numberFormat3FractionalDigits,cellStyle: cellAlignment},
        {headerName: 'Market Value', field: 'mnyMarketValue_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'Initial Request', field: 'mnyInitReq_5', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'Initial Request Rate', field: 'rtInitReqRt_6'},
        {headerName: 'House Base Margin', field: 'mnyHouseBaseMargin_8', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'House Base Margin Rate', field: 'rtHouseBaseMarginRt_4'},
        {headerName: 'Liquidity Add-on', field: 'mnyLiquidityAdd-on_7', valueFormatter: numberFormatter},
        {headerName: 'Concentration Add-on', field: 'mnyConcentrationAdd-on_9', valueFormatter: numberFormatter},
        {headerName: 'House Risk Margin', field: 'mnyHouseRiskMargin_8', valueFormatter: numberFormatter},
        {headerName: 'House Risk Margin Rate', field: 'rtHouseRiskMarginRt_4'},
        {headerName: 'FX(USD)', field: 'fxFx(USD)_5', valueFormatter: numberFormat5FractionalDigits,cellStyle: cellAlignment},
        {headerName: 'Maturity Date', field: 'Maturity Date_4', valueGetter: (params)=>stringToDate(dateToString(params.data['Maturity Date_4']))},
        // {headerName: 'Comments', field: 'Comments_1'}, -- Display only this if ( @cdClient like '025%')
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['Margin Requirements-Special', [
        {headerName: 'Comments', field: 'Comment_1'}, //-- Display only this if ( @cdClient like '025%')
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['Missing Prices', [
        {headerName: 'Class', field: 'Class_4'},
        {headerName: 'Symbol', field: 'Symbol_5'},
        {headerName: 'Cusip', field: 'Cusip_5'},
        {headerName: 'Sedol', field: 'Sedol_5'},
        {headerName: 'Isin', field: 'Isin_5'},
        {headerName: 'Ccy', field: 'Ccy_3'},
        {headerName: 'Description', field: 'Description_20'},
        {headerName: 'Qty', field: 'shsQty_5', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'PriceDt', field: 'PriceDt_5', valueGetter: (params)=>stringToDate(dateToString(params.data['PriceDt_5']))},
        {headerName: 'Price', field: 'pxPrice_5', valueFormatter: numberFormat3FractionalDigits,cellStyle: cellAlignment, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'cdPxCcy', field: 'cdPxCcy_5'},
        {headerName: 'Effective', field: 'Effective_5', valueGetter: (params)=>stringToDate(dateToString(params.data['Effective_5']))},
        {headerName: 'AcctNbr', field: 'AcctNbr_5'},
        {headerName: 'Comments', field: 'Comments_1'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['Options Expiration', [
        {headerName: 'CCY', field: 'Ccy_3'},
        {headerName: 'Type', field: 'Type_3'},
        {headerName: 'Security', field: 'Security_5'},
        {headerName: 'Class', field: 'Class_3'},
        {headerName: 'Symbol', field: 'Symbol_8'},
        {headerName: 'Name', field: 'Name_12'},
        {headerName: 'Expire', field: 'Expire_4'},
        {headerName: 'Quantity', field: 'shsQuantity_5', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'Value', field: 'mnyValue_6', valueFormatter: numberFormatter, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Close Price', field: 'pxPriceClose_4', valueFormatter: numberFormatter},
        {headerName: 'Money In', field: 'pxInMny_4', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'CCY Price', field: 'PriceCcy_3', isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'Activity', field: 'shsActivity_4', isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Open', field: 'shsOpen_4'},
        {headerName: 'Account', field: 'Acct_4'},
        {headerName: 'Comments', field: 'Comments_1'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['Positions', [
        {headerName: 'Class', field: 'Class_3'},
        {headerName: 'Type', field: 'Type_2'},
        {headerName: 'LS', field: 'LS_2'},
        {headerName: 'CCY', field: 'CCY_3'},
        {headerName: 'Symbol', field: 'Symbol_3'},
        {headerName: 'Security', field: 'Security_7'},
        {headerName: 'Security Description', field: 'Security Description_12'},
        {headerName: 'Quantity', field: 'shsQuantity_5', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'Price', field: 'pxPrice_4', valueFormatter: numberFormat3FractionalDigits,cellStyle: cellAlignment, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'PCY', field: 'Pcy_3'},
        {headerName: 'Cost', field: 'mnyCost_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Value', field: 'mnyValue_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Unrealized', field: 'mnyUnrealized_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'CPS(USD)', field: 'pxCPS(USD)_6', valueFormatter: numberFormat3FractionalDigits,cellStyle: cellAlignment, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'Cost(USD)', field: 'mnyCost(USD)_7', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Value(USD)', field: 'mnyValue(USD)_7', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Unrealized(USD)', field: 'mnyUnrealized(USD)_8', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'FX(USD)', field: 'fxFx(USD)_5', valueFormatter: numberFormat5FractionalDigits,cellStyle: cellAlignment, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'ROI', field: 'rtROI_4', valueFormatter: numberFormat5FractionalDigits, cellStyle: cellStyleChecker, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'Cntrib', field: 'rtCntrib_4', valueFormatter: numberFormat5FractionalDigits, cellStyle: cellStyleChecker, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'Money In', field: 'mnyInMny_4', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Account', field: 'Account_4'},
        {headerName: 'Comments', field: 'Comments_10'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['Positions Long Short', [
        {headerName: 'Manager', field: 'ManagerCode_4'},
        {headerName: 'Account', field: 'Acct_4'},
        {headerName: 'Account Name', field: 'AccountName_5'},
        {headerName: 'Type', field: 'Type_2'},
        {headerName: 'LS', field: 'LS_2'},
        {headerName: 'Currency', field: 'CCY_3'},
        {headerName: 'Security', field: 'Security_7'},
        {headerName: 'Security Description', field: 'SecurityDescription_12'},
        {headerName: 'Quantity', field: 'Quantity_5', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Price', field: 'Price_4', valueFormatter: numberFormat3FractionalDigits,cellStyle: cellAlignment, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'PCY', field: 'PCy_3'},
        {headerName: 'Value USD', field: 'ValueUSD_7', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: '', field: '', type: 'flexColType'}
    ]],
    ['SD Cash Flow Detail', [
        {headerName: 'Query Type', field: 'Query Type_5'},
        {headerName: 'Account', field: 'Account_4'},
        {headerName: 'Type', field: 'Type_4'},
        {headerName: 'CCY', field: 'CCY_3'},
        {headerName: 'Effective Date', field: 'Effective Date_4', valueGetter: (params)=>stringToDate(dateToString(params.data['Effective Date_4']))},
        {headerName: 'Entry Date', field: 'Entry Date_4', valueGetter: (params)=>stringToDate(dateToString(params.data['Entry Date_4']))},
        {headerName: 'Settle Date', field: 'Settle Date_4', valueGetter: (params)=> params.data['Settle Date_4'] === "        " || params.data['Settle Date_4'] ==='' || params.data['Settle Date_4'] === null ? '' : stringToDate(dateToString(params.data['Settle Date_4']))},
        {headerName: 'Trade Date', field: 'Trade Date_4', valueGetter: (params)=>stringToDate(dateToString(params.data['Trade Date_4']))},
        {headerName: 'BS', field: 'BS_1'},
        {headerName: 'Class', field: 'Class_2'},
        {headerName: 'Symbol', field: 'Symbol_5'},
        {headerName: 'Security Description', field: 'Security Description_12'},
        {headerName: 'Net SD Amount(USD)', field: 'mnyNet SD Amount(USD)_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'Quantity', field: 'shsQuantity_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Price', field: 'pxPrice_5', valueFormatter: numberFormatter, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'SD Amount', field: 'mnySD Amount_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'Fx(USD)', field: 'fxFx(USD)_4', valueFormatter: numberFormat5FractionalDigits,cellStyle: cellAlignment, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'SD Amount(USD)', field: 'mnySD Amount(USD)_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'Comm', field: 'mnyComm_4', valueFormatter: numberFormat3FractionalDigits, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'TcktChg', field: 'mnyTcktChg_4', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Fee', field: 'mnyFee_4', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Interest', field: 'mnyInterest_4', valueFormatter: numberFormat3FractionalDigits, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Exec Brk', field: 'Exec brk_3'},
        {headerName: 'Contra', field: 'Contra_3'},
        {headerName: 'Tran', field: 'Tran_3'},
        {headerName: 'Source', field: 'Source_2'},
        {headerName: 'Comments', field: 'Comments_2'},
        {headerName: '', field: '', type: 'flexColType'}
    ]],
    ['SDPositions', [
        {headerName: 'Class', field: 'Class_3'},
        {headerName: 'Type', field: 'Type_2'},
        {headerName: 'LS', field: 'LS_2'},
        {headerName: 'CCY', field: 'CCY_3'},
        {headerName: 'Symbol', field: 'Symbol_3'},
        {headerName: 'bbg', field: 'bbg_symbol'},
        {headerName: 'Security', field: 'Security_7'},
        {headerName: 'Security Description', field: 'Security Description_12'},
        {headerName: 'Quantity', field: 'shsQuantity_5', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'Price', field: 'pxPrice_4', valueFormatter: numberFormat3FractionalDigits,cellStyle: cellAlignment, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'Pcy', field: 'Pcy_3'},
        {headerName: 'UnitCost', field: 'UnitCost', valueFormatter: numberFormat4FractionalDigits, cellStyle: cellStyleChecker},
        {headerName: 'cost', field: 'Cost', valueFormatter: numberFormat4FractionalDigits, cellStyle: cellStyleChecker},
        {headerName: 'Value', field: 'mnyValue_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Unrealized', field: 'Unrealized', valueFormatter: numberFormat3FractionalDigits},
        {headerName: 'CostUSD', field: 'CostUSD', valueFormatter: numberFormat3FractionalDigits},
        {headerName: 'Value(USD)', field: 'mnyValue(USD)_7', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'UnrealizedUSD', field: 'UnrealizedUSD', valueFormatter: numberFormat5FractionalDigits},
        {headerName: 'Fx(USD)', field: 'fxFx(USD)_5', valueFormatter: numberFormat5FractionalDigits, cellStyle: cellStyleChecker, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'ROI', field: 'ROI', valueFormatter: numberFormat5FractionalDigits},
        {headerName: 'Contr', field: 'Contr', valueFormatter: numberFormat5FractionalDigits},
        {headerName: 'InMny', field: 'InMny', valueFormatter: numberFormat5FractionalDigits},
        {headerName: 'Effective', field: 'Effective', valueGetter: (params)=>stringToDate(dateToString(params.data['Effective']))},
        {headerName: 'Account', field: 'Account_4'},
        {headerName: 'Er', field: 'Er_2'},
        {headerName: 'isin', field: 'isin'},
        {headerName: 'sedol', field: 'sedol'},
        {headerName: 'cusip', field: 'cusip'},
        {headerName: 'sedolOrTicker', field: 'sedolOrTicker'},
        {headerName: 'Comments', field: 'Comments_10'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['Short Sale Approval', [
        {headerName: 'Requester', field: 'Requester_4'},
        {headerName: 'Contact', field: 'Contact_4'},
        {headerName: 'Basket', field: 'Basket_4'},
        {headerName: 'Request Time', field: 'ReqTime_8'},
        {headerName: 'Requested', field: 'Requested_4'},
        {headerName: 'Security', field: 'Security_15'},
        {headerName: 'RegSho', field: 'RegSho_3'},
        {headerName: 'Ask', field: 'shsAsk_4', cellStyle: cellStyleChecker},
        {headerName: 'Fill', field: 'shsFill_4', cellStyle: cellStyleChecker},
        {headerName: 'Kill', field: 'shsKill_4', cellStyle: cellStyleChecker},
        {headerName: 'Lvs',  field : 'shsLvs_4', cellStyle: cellStyleChecker},
        {headerName: 'Indicative Rate', field: 'rtIndicativeRate_4' },
        {headerName: 'House Comment', field: 'House Comment_10'},
        {headerName: 'TimeStamp', field: 'TimeStamp_8', valueGetter: (params)=>formatDateString(params.data['TimeStamp_8'], "MM/DD/yyyy hh:mm:ss")},
        {headerName: 'Approval Id', field: 'Approval id_5'},
        {headerName: 'Account', field: 'Account_6'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
     ['Stock Loan Accruals', [
        {headerName: 'Class', field: 'Class_3'},
        {headerName: 'Accrual Date', field: 'AccrualDate_4', valueGetter: (params)=>stringToDate(dateToString(params.data['AccrualDate_4']))},
        {headerName: 'Symbol', field: 'Symbol_5'},
        {headerName: 'Name', field: 'Name_15'},
        {headerName: 'Type', field: 'Type_3'},
        {headerName: 'Quantity', field: 'shsQuantity_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Borrow Price', field: 'pxBrwPrice_5', valueFormatter: numberFormatter, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'Loan Value', field: 'mnyLoanValue_6', valueFormatter: numberFormatter, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Rate', field: 'rtRate_5', valueFormatter: numberFormatter, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'Accrual', field: 'mnyAccrual_6', valueFormatter: numberFormat3FractionalDigits, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'CCY', field: 'CCY_2'},
        {headerName: 'PX CCY', field: 'CCYPx_3'},
        {headerName: 'Close', field: 'pxClose_3', isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'Fx', field: 'pxFx_3', isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'HTB', field: 'HTB_5'},
        {headerName: 'Account', field: 'Account_5'},
        {headerName: 'Comments', field: 'Comments'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['Tax Lots - Closed', [
        {headerName: 'Class', field: 'Class_4'},
        {headerName: 'CCY', field: 'CCY_3'},
        {headerName: 'Trade Date', field: 'Trade Date_4', valueGetter: (params)=>stringToDate(dateToString(params.data['Trade Date_4']))},
        {headerName: 'Close Date', field: 'Close Date_4', valueGetter: (params)=>stringToDate(dateToString(params.data['Close Date_4']))},
        {headerName: 'Symbol', field: 'Symbol_5'},
        {headerName: 'Security Description', field: 'Security Description_12'},
        {headerName: 'Term', field: 'Term_2'},
        {headerName: 'Lot No.', field: 'Lot Nbr_4'},
        {headerName: 'Quantity', field: 'shsQuantity_5', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'CostPx', field: 'pxCostPx_5', valueFormatter: numberFormat3FractionalDigitsStr},
        {headerName: 'CostValue', field: 'mnyCostValue_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'CloseValue', field: 'mnyCloseValue_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Realized', field: 'mnyRealized_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'Realized(USD)', field: 'mnyRealized(USD)_7', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'ROI', field: 'rtROI_5', valueFormatter: numberFormat4FractionalDigitsPer, cellStyle: cellStyleChecker},
        {headerName: 'Cntrib', field: 'rtCntrib_5', valueFormatter: numberFormat4FractionalDigitsPer, cellStyle: cellStyleChecker},
        {headerName: 'Account', field: 'Account_4'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['Tax Lots - Open', [
        {headerName: 'Class', field: 'Class_3'},
        {headerName: 'Type', field: 'Type_3'},
        {headerName: 'CCY', field: 'CCY_3'},
        {headerName: 'Symbol', field: 'Symbol_5'},
        {headerName: 'Security Description', field: 'Security Description_12'},
        {headerName: 'Trade Date', field: 'Trade Date_3', valueGetter: (params)=>stringToDate(dateToString(params.data['Trade Date_3']))},
        {headerName: 'Term', field: 'Term_2'},
        {headerName: 'Quantity', field: 'shsQuantity_4', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'PX Cost', field: 'pxCostPx_4', isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'Current PX', field: 'pxCurrPx_4', isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'Cost Value', field: 'mnyCostValue_4', valueFormatter: numberFormatter, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Current Value', field: 'mnyCurrValue_4', valueFormatter: numberFormatter},
        {headerName: 'Unrealized', field: 'mnyUnrealized_4', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'FX Cost', field: 'fxCostFx_4', isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'Cost Value(USD)', field: 'mnyCostValue(USD)_5', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Unrealized(USD)', field: 'mnyUnrealized(USD)_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'ROI', field: 'rtROI_3', valueFormatter: numberFormat5FractionalDigits, cellStyle: cellStyleChecker, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'Fx(USD)', field: 'fxFx(USD)_4', valueFormatter: numberFormat5FractionalDigits,cellStyle: cellAlignment, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'Activity', field: 'Activity_3'},
        {headerName: 'Lot No.', field: 'Lot Nbr_4'},
        {headerName: 'Account', field: 'Account_4'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['Trade Breaks', [
        {headerName: 'Break Type', field: 'Break Type_6'},
        {headerName: 'Account', field: 'Account_5'},
        {headerName: 'Trade Date', field: 'Trade Date_4',valueGetter: (params)=>stringToDate(dateToString(params.data['Trade Date_4']))},
        {headerName: 'Settle Date', field: 'Settle Date_4',valueGetter: (params)=>stringToDate(dateToString(params.data['Settle Date_4']))},
        {headerName: 'BS', field: 'BS_3'},
        {headerName: 'Security', field: 'Security_5'},
        {headerName: 'Price', field: 'pxPrice_4', isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'Quantity', field: 'shsQuantity_5', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'NetValue', field: 'mnyNetValue_5', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'CCY', field: 'ccy'},
        {headerName: 'Interest', field: 'mnyInterest_4', valueFormatter: numberFormat5FractionalDigits, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Commission', field: 'mnyComm_5', valueFormatter: numberFormat5FractionalDigits, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'EXEC BRK', field: 'Exec Brk_3'},
        {headerName: 'Broker Code', field: 'BrokerCode'},
        {headerName: 'DTCControlNumber', field: 'DTCControlNumber_5'},
        {headerName: 'Side', field: 'Side_3'},
        {headerName: 'Reason', field: 'Reason_10'}
    ]],
    ['Trades', [
        {headerName: 'Class', field: 'Class_3'},
        {headerName: 'Type', field: 'Type_3'},
        {headerName: 'CCY', field: 'CCY_3'},
        {headerName: 'BS', field: 'BS_2'},
        {headerName: 'SS', field: 'SS_2'},
        {headerName: 'OC', field: 'OC_2'},
        {headerName: 'Symbol', field: 'Symbol_8'},
        {headerName: 'Security Description', field: 'Security Description_12'},
        {headerName: 'Trade Date', field: 'Trade Date_4', valueGetter: (params)=>stringToDate(dateToString(params.data['Trade Date_4']))},
        {headerName: 'Quantity', field: 'shsQuantity_5', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Price', field: 'pxPrice_4', valueFormatter: numberFormat3FractionalDigits,cellStyle: cellAlignment, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'Amount', field: 'mnyAmount_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'COMM', field: 'mnyComm_5', valueFormatter: numberFormat3FractionalDigits, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'TcktChg', field: 'mnyTcktChg_5', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Fee', field: 'mnyFee_5', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Interest', field: 'mnyInterest_4', valueFormatter: numberFormat3FractionalDigits, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Net Value', field: 'mnyNetValue_5', valueFormatter: numberFormatter, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Settle Date', field: 'Settle Date_4', valueGetter: (params)=>stringToDate(dateToString(params.data['Settle Date_4']))},
        {headerName: 'Entry Date', field: 'Entry Date_4', valueGetter: (params)=>stringToDate(dateToString(params.data['Entry Date_4']))},
        {headerName: 'Exec Brk', field: 'Exec Brk_3'},
        {headerName: 'CONTRA', field: 'Contra_5'},
        {headerName: 'Security', field: 'Security_5'},
        {headerName: 'Tran', field: 'Tran_3'},
        {headerName: 'Account', field: 'Account_4'},
        {headerName: 'Comment', field: 'Comment_1'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true},
    ]],
    ['Transactions', [
        {headerName: 'CCY', field: 'CCY_3'},
        {headerName: 'Activity Type', field: 'Activity Type_4'},
        {headerName: 'Trade Date', field: 'Trade Date_4', valueGetter: (params)=>stringToDate(dateToString(params.data['Trade Date_4']))},
        {headerName: 'Settle Date', field: 'Settle Date_4', valueGetter: (params)=>stringToDate(dateToString(params.data['Settle Date_4']))},
        {headerName: 'Entry Date', field: 'Entry Date_4', valueGetter: (params)=>stringToDate(dateToString(params.data['Entry Date_4']))},
        {headerName: 'Activity', field: 'Activity_5'},
        {headerName: 'Security Description', field: 'Security Description_12'},
        {headerName: 'Security', field: 'Security_5'},
        {headerName: 'TD Balance', field: 'mnyTDBalance_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Quantity', field: 'shsQuantity_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Amount', field: 'mnyAmount_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Price', field: 'pxPrice_5', valueFormatter: numberFormat3FractionalDigits,cellStyle: cellAlignment, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'Comm', field: 'mnyComm_4', valueFormatter: numberFormat3FractionalDigits, cellStyle: cellStyleChecker},
        {headerName: 'Fee', field: 'mnyFee_4', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'TcktChg', field: 'mnyTcktChg_4', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Trailer', field: 'Trailer_5'},
        {headerName: 'Tran', field: 'Tran_3'},
        {headerName: 'FX(USD)', field: 'fxFx(USD)_4', valueFormatter: numberFormat5FractionalDigits,cellStyle: cellAlignment, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'Account', field: 'Account_4'},
        {headerName: 'Comments', field: 'Comments_1'},
        {headerName: 'Action', field: 'Action_10'},
        {headerName: 'Source', field: 'Source_5'},
        {headerName: 'Received', field: 'shsReceived_6', valueFormatter: numberFormatter, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Delivered', field: 'shsDelivered_6', valueFormatter: numberFormatter, isSumEnabled:true, isAvgEnabled:false},
        {headerName: '', field: '', type: 'flexColType'}
    ]],
    ['Upcoming Dividends', [
        {headerName: 'Class', field: 'Class_3'},
        {headerName: 'Type', field: 'Type_2'},
        {headerName: 'CCY', field: 'Ccy_3'},
        {headerName: 'Symbol', field: 'Symbol_3'},
        {headerName: 'Security', field: 'Security_5'},
        {headerName: 'Security Description', field: 'Security Description_10'},
        {headerName: 'Quantity', field: 'shsQuantity_4', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Dividend', field: 'pxDividend_4', isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'Estimated Income', field: 'mnyEstIncome_4', valueFormatter: numberFormatter, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Ex Date', field: 'ExDate_3'},
        {headerName: 'Rec Date', field: 'RecDate_3'},
        {headerName: 'Pay Date', field: 'PayDate_3'},
        {headerName: 'Pay Method', field: 'PayMethod_3'},
        {headerName: 'Account', field: 'Account_4'},
        {headerName: 'Comments', field: 'Comments_1'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['ABF - Long Position', [
        {headerName: 'Effective Date', field: 'effective_date',valueFormatter: formatDateStringToDate,},
        {headerName: 'Account', field: 'account'},
        {headerName: 'Fund Name', field: 'fundName'},
        {headerName: 'Long Name', field: 'name_long'},
        {headerName: 'Position Raw Type', field: 'position_raw_type'},
        {headerName: 'CCY', field: 'CCY'},
        {headerName: 'Security Code', field: 'Security_code'},
        {headerName: 'Client Asset class', field: 'client_asset_class'},
        {headerName: 'Symbol', field: 'symbol'},
        {headerName: 'Security Description', field: 'security_description'},
        {headerName: 'Quantity SD', field: 'shsQuantitySD', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'Value SD', field: 'mnyValueSD', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'Price', field: 'pxPrice', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'CCY PX', field: 'ccy_px'},
        {headerName: 'FX(USD)', field: 'fxFxUSD', valueFormatter: numberFormat5FractionalDigits,cellStyle: cellAlignment},
        {headerName: 'Value SD USD', field: 'mnyValueSDUSD', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['ABF - Debit Summary', [
        {headerName: 'Effective Date', field: 'effective_date',valueFormatter: formatDateStringToDate,},
        {headerName: 'Account', field: 'account'},
        {headerName: 'Account Name', field: 'account_name'},
        {headerName: 'Client Asset Class', field: 'client_asset_class'},
        {headerName: 'Balance', field: 'MnyBalance', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'Rate', field: 'rate', cellStyle: cellStyleChecker, valueFormatter: numberFormatter},
        {headerName: 'Single Day', field: 'single_day', valueFormatter: numberFormat3FractionalDigits, cellStyle: cellStyleChecker},
        {headerName: 'MTD Accrual', field: 'mtd_accural', valueFormatter: numberFormat3FractionalDigits, cellStyle: cellStyleChecker},
        {headerName: 'YTD Accrual', field: 'ytd_accural', valueFormatter: numberFormat3FractionalDigits, cellStyle: cellStyleChecker},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['ABF - Top Summary', [
        {headerName: 'Effective Date', field: 'effective_date',valueFormatter: formatDateStringToDate,},
        {headerName: 'Account', field: 'account'},
        {headerName: 'Account Name', field: 'account_name'},
        {headerName: 'Description', field: 'description'},
        {headerName: 'Balance', field: 'MnyBalance', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'Rate', field: 'rate', cellStyle: cellStyleChecker, valueFormatter: numberFormatter},
        {headerName: 'Single Day', field: 'single_day', valueFormatter: numberFormat3FractionalDigits, cellStyle: cellStyleChecker},
        {headerName: 'MTD Accrual', field: 'mtd_accural', valueFormatter: numberFormat3FractionalDigits, cellStyle: cellStyleChecker},
        {headerName: 'YTD Accrual', field: 'ytd_accural', valueFormatter: numberFormat3FractionalDigits, cellStyle: cellStyleChecker},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
]);

export const queryGridDataColumnDefsPortal = new Map([
    ['Account Information', [
        {headerName: 'Key', field: 'Key_10'},
        {headerName: 'Value', field: 'Value_80'},
        {headerName: 'Comments', field: 'Comments_1'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['Cash Balances', [
        {headerName: 'CCY', field: 'CCY_3'},
        {headerName: 'Type', field: 'Type_2'},
        {headerName: 'TDBalance', field: 'mnyTDBalance_8', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'SDBalance', field: 'mnySDBalance_8', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'FX(USD)', field: 'fxFx(USD)_4', valueFormatter: numberFormat5FractionalDigits,cellStyle: cellAlignment, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'TDBalance(USD)', field: 'mnyTDBalance(USD)_8', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'SDBalance(USD)', field: 'mnySDBalance(USD)_8', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Effective Date', field: 'Effective Date_7', valueGetter: (params)=>stringToDate(dateToString(params.data['Effective Date_7']))},
        {headerName: 'Account', field: 'Account_4'},
        {headerName: 'Comments', field: 'Comments_1'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['Cash Flow Detail', [
        {headerName: 'CCY', field: 'CCY_3'},
        {headerName: 'Activity Type', field: 'Activity Type_4'},
        {headerName: 'Trade Date', field: 'Trade Date_4', valueGetter: (params)=>stringToDate(dateToString(params.data['Trade Date_4']))},
        {headerName: 'Settle Date', field: 'Settle Date_4', valueGetter: (params)=>stringToDate(dateToString(params.data['Settle Date_4']))},
        {headerName: 'Entry Date', field: 'Entry Date_4', valueGetter: (params)=>stringToDate(dateToString(params.data['Entry Date_4']))},
        {headerName: 'Activity', field: 'Activity_5'},
        {headerName: 'Security Description', field: 'Security Description_15'},
        {headerName: 'Security', field: 'Security_5'},
        {headerName: 'TDBalance', field: 'mnyTDBalance_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Quantity', field: 'shsQuantity_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Amount', field: 'mnyAmount_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'RunTotal', field: 'mnyRunTotal_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Price', field: 'pxPrice_5', valueFormatter: numberFormat3FractionalDigits,cellStyle: cellAlignment, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'COMM', field: 'mnyComm_4', valueFormatter: numberFormat3FractionalDigits, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Fee', field: 'mnyFee_4', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'TcktChg', field: 'mnyTcktChg_4', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Trailer', field: 'Trailer_5'},
        {headerName: 'Tran', field: 'Tran_3'},
        {headerName: 'FX(USD)', field: 'fxFx(USD)_4', valueFormatter:numberFormat5FractionalDigits,cellStyle: cellAlignment, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'Account', field: 'Account_4'},
        {headerName: 'Comments', field: 'Comments_1'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['Cash Flow Summary', [
        {headerName: 'CCY', field: 'CCY_3'},
        {headerName: 'Type', field: 'Type_2'},
        {headerName: 'Activity Type', field: 'Activity Type_4'},
        {headerName: 'Balance Date', field: 'Balance Date_6', valueGetter: (params)=> params.data['Balance Date_6'] === "        " || params.data['Balance Date_6'] ==='' || params.data['Balance Date_6'] === null ? '' : stringToDate(dateToString(params.data['Balance Date_6']))},
        {headerName: 'Activity', field: 'Activity_8'},
        {headerName: 'TDBalance', field: 'mnyTDBalance_8', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Amount', field: 'mnyAmount_8', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Account', field: 'Account_4'},
        {headerName: 'Comments', field: 'Comments_1'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['Commissions', [
        {headerName: 'Class', field: 'Class_3'},
        {headerName: 'CCY', field: 'CCY_2'},
        {headerName: 'BS', field: 'BS_2'},
        {headerName: 'SS', field: 'SS_2'},
        {headerName: 'OC', field: 'OC_2'},
        {headerName: 'Symbol', field: 'Symbol_5'},
        {headerName: 'Security Description', field: 'Security Description_12'},
        {headerName: 'Quantity', field: 'shsQuantity_5', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'TradeDate', field: 'TradeDate_4', valueGetter: (params)=>stringToDate(dateToString(params.data['TradeDate_4']))},
        {headerName: 'COMM', field: 'mnyComm_5', valueFormatter: numberFormat3FractionalDigits, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Exec Brk', field: 'Exec Brk_4'},
        {headerName: 'Contra', field: 'Contra_6'},
        {headerName: 'Exchange', field: 'Exchange_4'},
        {headerName: 'Tran', field: 'Tran_4'},
        {headerName: 'EntryDate', field: 'EntryDate_4', valueGetter: (params)=>stringToDate(dateToString(params.data['EntryDate_4']))},
        {headerName: 'Account', field: 'Account_4'},
        {headerName: 'Comments', field: 'Comments_1'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['Dividends Paid', [
        {headerName: 'CCY', field: 'CCY_3'},
        {headerName: 'Type', field: 'Type_3'},
        {headerName: 'Trade Date', field: 'Trade Date_4', valueGetter: (params)=>stringToDate(dateToString(params.data['Trade Date_4']))},
        {headerName: 'Entry Date', field: 'Entry Date_4', valueGetter: (params)=>stringToDate(dateToString(params.data['Entry Date_4']))},
        {headerName: 'Activity', field: 'Activity_8'},
        {headerName: 'Security Description', field: 'Security Description_12'},
        {headerName: 'Security', field: 'Security_5'},
        {headerName: 'Amount', field: 'mnyAmount_5', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Trailer', field: 'Trailer_10'},
        {headerName: 'Tran', field: 'Tran_4'},
        {headerName: 'Fx(USD)', field: 'fxFx(USD)_5', valueFormatter: numberFormat5FractionalDigits,cellStyle: cellAlignment, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'Account', field: 'Account_4'},
        {headerName: 'Comments', field: 'Comments_1'},
        {headerName: 'Symbol', field: 'Ticker_13', hide: true},
        {headerName: 'Quantity', field: 'PayoutQuantity_14', hide: true},
        {headerName: 'Div Rate', field: 'DividendRate_15', valueFormatter: numberFormat5FractionalDigits, hide: true},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['Fails - Open', [
        {headerName: 'Class', field: 'Class_3'},
        {headerName: 'CCY', field: 'CCY_3'},
        {headerName: 'Contra', field: 'Contra_4'},
        {headerName: 'Cage DTC', field: 'Contra DTC_4'},
        {headerName: 'Contra Name', field: 'Contra Name_8'},
        {headerName: 'Security', field: 'Security_5'},
        {headerName: 'Security Description', field: 'Security Description_12'},
        {headerName: 'Entry Date', field: 'Entry Date_4', valueGetter: (params)=>stringToDate(dateToString(params.data['Entry Date_4']))},
        {headerName: 'Cusip', field: 'Cusip'},
        {headerName: 'ISIN', field: 'ISIN'},
        {headerName: 'Sedol', field: 'Sedol'},
        {headerName: 'Symbol', field: 'Symbol'},
        {headerName: 'Trade Date', field: 'Trade Date_4', valueGetter: (params)=>stringToDate(dateToString(params.data['Trade Date_4']))},
        {headerName: 'Settle Date', field: 'Settle Date_4', valueGetter: (params)=>stringToDate(dateToString(params.data['Settle Date_4']))},
        {headerName: 'B/S', field: 'B/S_2'},
        {headerName: 'Quantity', field: 'shsQuantity_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'Amount', field: 'mnyAmount_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Price', field: 'Price', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Original Quantity', field: 'shsQuantityOrig_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'Original Amount', field: 'mnyAmountOrig_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Tran', field: 'Tran_4'},
        {headerName: 'Status', field: 'Status_3'},
        {headerName: 'Account', field: 'Account_4'},
        {headerName: 'Effective', field: 'Comments_1'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['Interest Accruals', [
        {headerName: 'CCY', field: 'CCY_3'},
        {headerName: 'Type', field: 'Type_2'},
        {headerName: 'AccrualDt', field: 'AccrualDt_4', valueGetter: (params)=>stringToDate(dateToString(params.data['AccrualDt_4']))},
        {headerName: 'Description', field: 'Description_10'},
        {headerName: 'Balance', field: 'mnyBalance_10', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'BenchMark', field: 'BenchMark_4'},
        {headerName: 'BMarkRate', field: 'rtBMarkRt_5', cellStyle: cellStyleChecker, valueFormatter: numberFormat4FractionalDigitsPer, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'Spread', field: 'rtSpread_5', cellStyle: cellStyleChecker, valueFormatter: numberFormat4FractionalDigitsPer, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'Rate', field: 'rtRate_5', cellStyle: cellStyleChecker, valueFormatter: numberFormat4FractionalDigitsPer, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'Accrual', field: 'mnyAccrual_6', valueFormatter: numberFormat3FractionalDigits, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'MTDAccrual', field: 'mnyMTDAccrual_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'DayCt', field: 'shsDayCt_5', cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Acct', field: 'Acct_5'},
        {headerName: 'Comments', field: 'Comments_1'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['Journals', [
        {headerName: 'CCY', field: 'CCY_2'},
        {headerName: 'Type', field: 'Type_2'},
        {headerName: 'MVMT', field: 'Mvmt_3'},
        {headerName: 'EntryDate', field: 'EntryDate_4', valueGetter: (params)=>stringToDate(dateToString(params.data['EntryDate_4']))},
        {headerName: 'Received', field: 'shsReceived_6', isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Delivered', field: 'shsDelivered_6', isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'CashIn', field: 'mnyCashIn_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'CashOut', field: 'mnyCashOut_5', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Security', field: 'Security_6'},
        {headerName: 'Security Description', field: 'SecurityDescription_12'},
        {headerName: 'Action', field: 'Action_10'},
        {headerName: 'Source', field: 'Source_5'},
        {headerName: 'Account', field: 'Account_4'},
        {headerName: 'Comments', field: 'Comments_1'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['Journals With Symbol', [
        {headerName: 'CCY', field: 'CCY_2'},
        {headerName: 'Type', field: 'Type_2'},
        {headerName: 'MVMT', field: 'Mvmt_3'},
        {headerName: 'EntryDate', field: 'EntryDate_4', valueGetter: (params)=>stringToDate(dateToString(params.data['EntryDate_4']))},
        {headerName: 'Received', field: 'shsReceived_6', isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Delivered', field: 'shsDelivered_6', isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Cashin', field: 'mnyCashIn_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Cashout', field: 'mnyCashOut_5', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Symbol', field: 'Symbol_3'},
        {headerName: 'Security', field: 'Security_7'},
        {headerName: 'SecurityDescription', field: 'SecurityDescription_12'},
        {headerName: 'Action', field: 'Action_10'},
        {headerName: 'Source', field: 'Source_5'},
        {headerName: 'Account', field: 'Account_4'},
        {headerName: 'Comments', field: 'Comments_1'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['LegacyPosRpt', [
        {headerName: 'Class', field: 'Class_3'},
        {headerName: 'Type', field: 'Type_3'},
        {headerName: 'CCY', field: 'CCY_3'},
        {headerName: 'Symbol', field: 'Symbol_4'},
        {headerName: 'Security Description', field: 'Security Descripiton_12'},
        {headerName: 'TradeDate', field: 'shsTradeDate_5', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'TradeDateValue', field: 'mnyTradeDateValue_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'SettleDate', field: 'shsSettleDate_5', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Price', field: 'pxPrice_4', valueFormatter: numberFormat3FractionalDigits, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'InMny', field: 'mnyInMny_4', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'CCY(Px)', field: 'CCY(Px)_3'},
        {headerName: 'Activity', field: 'shsActivity_4', cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'TrdDtOpen', field: 'shsTrdDtOpen_5', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Pending', field: 'shsPending_4', cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Dom', field: 'Dom_3'},
        {headerName: 'Security', field: 'Security_5'},
        {headerName: 'PrxSrc', field: 'PrxSrc_5'},
        {headerName: 'Account', field: 'Acct_4'},
        {headerName: 'Comments', field: 'Comments_1'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['Margin Requirements', [
        {headerName: 'Class', field: 'Class_2'},
        {headerName: 'Type', field: 'Type_3'},
        {headerName: 'Strategy', field: 'Strategy_3'},
        {headerName: 'LS', field: 'LS_2'},
        {headerName: 'CCY', field: 'CCY_2'},
        {headerName: 'Symbol', field: 'Symbol_4'},
        {headerName: 'Security Description', field: 'Security Description_12'},
        {headerName: 'Quantity', field: 'shsQuantity_5', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'Price', field: 'pxPrice_4', valueFormatter: numberFormat3FractionalDigits,cellStyle: cellAlignment},
        {headerName: 'MarketValue', field: 'mnyMarketValue_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'InitReq', field: 'mnyInitReq_5', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'InitReqRt', field: 'rtInitReqRt_6'},
        {headerName: 'HouseBaseMargin', field: 'mnyHouseBaseMargin_8', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'HouseBaseMarginRt', field: 'rtHouseBaseMarginRt_4'},
        {headerName: 'LiquidityAdd-on', field: 'mnyLiquidityAdd-on_7', valueFormatter: numberFormatter},
        {headerName: 'ConcentrationAdd-on', field: 'mnyConcentrationAdd-on_9', valueFormatter: numberFormatter},
        {headerName: 'HouseRiskMargin', field: 'mnyHouseRiskMargin_8', valueFormatter: numberFormatter},
        {headerName: 'HouseRiskMarginRt', field: 'rtHouseRiskMarginRt_4'},
        {headerName: 'Fx(USD)', field: 'fxFx(USD)_5', valueFormatter: numberFormat5FractionalDigits,cellStyle: cellAlignment},
        {headerName: 'Maturity Date', field: 'Maturity Date_4', valueGetter: (params)=>stringToDate(dateToString(params.data['Maturity Date_4']))},
        // {headerName: 'Comments', field: 'Comments_1'}, -- Display only this if ( @cdClient like '025%')
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]], ['Margin Requirements-Special', [
        {headerName: 'Comments', field: 'Comment_1'}, //-- Display only this if ( @cdClient like '025%')
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['Missing Prices', [
        {headerName: 'Class', field: 'Class_4'},
        {headerName: 'Symbol', field: 'Symbol_5'},
        {headerName: 'CUSIP', field: 'Cusip_5'},
        {headerName: 'Sedol', field: 'Sedol_5'},
        {headerName: 'ISIN', field: 'Isin_5'},
        {headerName: 'CCY', field: 'Ccy_3'},
        {headerName: 'Security Description', field: 'Description_20'},
        {headerName: 'Quantity', field: 'shsQty_5', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'PriceDt', field: 'PriceDt_5', valueGetter: (params)=>stringToDate(dateToString(params.data['PriceDt_5']))},
        {headerName: 'Price', field: 'pxPrice_5', valueFormatter: numberFormat3FractionalDigits,cellStyle: cellAlignment, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'CdPxCcy', field: 'cdPxCcy_5'},
        {headerName: 'Effective', field: 'Effective_5', valueGetter: (params)=>stringToDate(dateToString(params.data['Effective_5']))},
        {headerName: 'AcctNbr', field: 'AcctNbr_5'},
        {headerName: 'Comments', field: 'Comments_1'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['Options Expiration', [
        {headerName: 'CCY', field: 'Ccy_3'},
        {headerName: 'Type', field: 'Type_3'},
        {headerName: 'Security', field: 'Security_5'},
        {headerName: 'Class', field: 'Class_3'},
        {headerName: 'Symbol', field: 'Symbol_8'},
        {headerName: 'Name', field: 'Name_12'},
        {headerName: 'Expire', field: 'Expire_4'},
        {headerName: 'Quantity', field: 'shsQuantity_5', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'Value', field: 'mnyValue_6', valueFormatter: numberFormatter, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'PriceClose', field: 'pxPriceClose_4', valueFormatter: numberFormatter},
        {headerName: 'InMny', field: 'pxInMny_4', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'PriceCcy', field: 'PriceCcy_3', valueFormatter: numberFormatter, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'Activity', field: 'shsActivity_4', isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Open', field: 'shsOpen_4'},
        {headerName: 'Acct', field: 'Acct_4'},
        {headerName: 'Comments', field: 'Comments_1'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['Positions', [
        {headerName: 'Class', field: 'Class_3'},
        {headerName: 'Type', field: 'Type_2'},
        {headerName: 'LS', field: 'LS_2'},
        {headerName: 'CCY', field: 'CCY_3'},
        {headerName: 'Symbol', field: 'Symbol_3'},
        {headerName: 'Security', field: 'Security_7'},
        {headerName: 'Security Description', field: 'Security Description_12'},
        {headerName: 'Quantity', field: 'shsQuantity_5', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'Price', field: 'pxPrice_4', valueFormatter: numberFormat3FractionalDigits,cellStyle: cellAlignment, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'PCY', field: 'Pcy_3'},
        {headerName: 'Cost', field: 'mnyCost_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Value', field: 'mnyValue_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Unrealized', field: 'mnyUnrealized_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'CPS(USD)', field: 'pxCPS(USD)_6', valueFormatter: numberFormat3FractionalDigits,cellStyle: cellAlignment, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'Cost(USD)', field: 'mnyCost(USD)_7', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Value(USD)', field: 'mnyValue(USD)_7', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Unrealized(USD)', field: 'mnyUnrealized(USD)_8', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'FX(USD)', field: 'fxFx(USD)_5', valueFormatter: numberFormat5FractionalDigits,cellStyle: cellAlignment, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'ROI', field: 'rtROI_4', valueFormatter: numberFormat5FractionalDigits, cellStyle: cellStyleChecker, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'Cntrib', field: 'rtCntrib_4', valueFormatter: numberFormat5FractionalDigits, cellStyle: cellStyleChecker, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'InMny', field: 'mnyInMny_4', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Account', field: 'Account_4'},
        {headerName: 'Comments', field: 'Comments_10'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['Positions Long Short', [
        {headerName: 'Manager', field: 'ManagerCode_4'},
        {headerName: 'Acct', field: 'Acct_4'},
        {headerName: 'AccountName', field: 'AccountName_5'},
        {headerName: 'Type', field: 'Type_2'},
        {headerName: 'LS', field: 'LS_2'},
        {headerName: 'Currency', field: 'CCY_3'},
        {headerName: 'Security', field: 'Security_7'},
        {headerName: 'SecurityDescription', field: 'SecurityDescription_12'},
        {headerName: 'Quantity', field: 'Quantity_5', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Price', field: 'Price_4', valueFormatter: numberFormat3FractionalDigits,cellStyle: cellAlignment, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'PCY', field: 'PCy_3'},
        {headerName: 'ValueUSD', field: 'ValueUSD_7', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: '', field: '', type: 'flexColType'}
    ]],
    ['SD Cash Flow Detail', [
        {headerName: 'Query Type', field: 'Query Type_5'},
        {headerName: 'Account', field: 'Account_4'},
        {headerName: 'Type', field: 'Type_4'},
        {headerName: 'CCY', field: 'CCY_3'},
        {headerName: 'Effective Date', field: 'Effective Date_4', valueGetter: (params)=>stringToDate(dateToString(params.data['Effective Date_4']))},
        {headerName: 'Entry Date', field: 'Entry Date_4', valueGetter: (params)=>stringToDate(dateToString(params.data['Entry Date_4']))},
        {headerName: 'Settle Date', field: 'Settle Date_4', valueGetter: (params)=> params.data['Settle Date_4'] === "        " || params.data['Settle Date_4'] ==='' || params.data['Settle Date_4'] === null ? '' : stringToDate(dateToString(params.data['Settle Date_4']))},
        {headerName: 'Trade Date', field: 'Trade Date_4', valueGetter: (params)=>stringToDate(dateToString(params.data['Trade Date_4']))},
        {headerName: 'BS', field: 'BS_1'},
        {headerName: 'Class', field: 'Class_2'},
        {headerName: 'Symbol', field: 'Symbol_5'},
        {headerName: 'Security Description', field: 'Security Description_12'},
        {headerName: 'Net SD Amount(USD)', field: 'mnyNet SD Amount(USD)_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'Quantity', field: 'shsQuantity_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Price', field: 'pxPrice_5', valueFormatter: numberFormatter, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'SD Amount', field: 'mnySD Amount_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'Fx(USD)', field: 'fxFx(USD)_4', valueFormatter: numberFormat5FractionalDigits,cellStyle: cellAlignment, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'SD Amount(USD)', field: 'mnySD Amount(USD)_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'Comm', field: 'mnyComm_4', valueFormatter: numberFormat3FractionalDigits, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'TcktChg', field: 'mnyTcktChg_4', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Fee', field: 'mnyFee_4', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Interest', field: 'mnyInterest_4', valueFormatter: numberFormat3FractionalDigits, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Exec Brk', field: 'Exec brk_3'},
        {headerName: 'Contra', field: 'Contra_3'},
        {headerName: 'Tran', field: 'Tran_3'},
        {headerName: 'Source', field: 'Source_2'},
        {headerName: 'Comments', field: 'Comments_2'},
        {headerName: '', field: '', type: 'flexColType'}
    ]],
    ['SDPositions', [
        {headerName: 'Class', field: 'Class_3'},
        {headerName: 'Type', field: 'Type_2'},
        {headerName: 'LS', field: 'LS_2'},
        {headerName: 'CCY', field: 'CCY_3'},
        {headerName: 'Symbol', field: 'Symbol_3'},
        {headerName: 'bbg', field: 'bbg_symbol'},
        {headerName: 'Security', field: 'Security_7'},
        {headerName: 'Security Description', field: 'Security Description_12'},
        {headerName: 'Quantity', field: 'shsQuantity_5', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'Price', field: 'pxPrice_4', valueFormatter: numberFormat3FractionalDigits,cellStyle: cellAlignment, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'Pcy', field: 'Pcy_3'},
        {headerName: 'UnitCost', field: 'UnitCost', valueFormatter: numberFormat4FractionalDigits, cellStyle: cellStyleChecker},
        {headerName: 'cost', field: 'Cost', valueFormatter: numberFormat4FractionalDigits, cellStyle: cellStyleChecker},
        {headerName: 'Value', field: 'mnyValue_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Unrealized', field: 'Unrealized', valueFormatter: numberFormat3FractionalDigits},
        {headerName: 'CostUSD', field: 'CostUSD', valueFormatter: numberFormat3FractionalDigits},
        {headerName: 'Value(USD)', field: 'mnyValue(USD)_7', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'UnrealizedUSD', field: 'UnrealizedUSD', valueFormatter: numberFormat5FractionalDigits},
        {headerName: 'Fx(USD)', field: 'fxFx(USD)_5', valueFormatter: numberFormat5FractionalDigits, cellStyle: cellStyleChecker, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'ROI', field: 'ROI', valueFormatter: numberFormat5FractionalDigits},
        {headerName: 'Contr', field: 'Contr', valueFormatter: numberFormat5FractionalDigits},
        {headerName: 'InMny', field: 'InMny', valueFormatter: numberFormat5FractionalDigits},
        {headerName: 'Effective', field: 'Effective', valueGetter: (params)=>stringToDate(dateToString(params.data['Effective']))},
        {headerName: 'Account', field: 'Account_4'},
        {headerName: 'Er', field: 'Er_2'},
        {headerName: 'isin', field: 'isin'},
        {headerName: 'sedol', field: 'sedol'},
        {headerName: 'cusip', field: 'cusip'},
        {headerName: 'sedolOrTicker', field: 'sedolOrTicker'},
        {headerName: 'Comments', field: 'Comments_10'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['Short Sale Approval', [
        {headerName: 'Requester', field: 'Requester_4'},
        {headerName: 'Contact', field: 'Contact_4'},
        {headerName: 'Basket', field: 'Basket_4'},
        {headerName: 'ReqTime', field: 'ReqTime_8', valueGetter: (params)=>formatDateString(params.data['ReqTime_8'], "MM/DD/yyyy hh:mm:ss")},
        {headerName: 'Requested', field: 'Requested_4'},
        {headerName: 'Security', field: 'Security_15'},
        {headerName: 'RegSho', field: 'RegSho_3'},
        {headerName: 'Ask', field: 'shsAsk_4', cellStyle: cellStyleChecker},
        {headerName: 'Fill', field: 'shsFill_4', cellStyle: cellStyleChecker},
        {headerName: 'Kill', field: 'shsKill_4', cellStyle: cellStyleChecker},
        {headerName: 'Lvs',  field : 'shsLvs_4' , cellStyle: cellStyleChecker},
        {headerName: 'IndicativeRate', field: 'rtIndicativeRate_4'},
        {headerName: 'House Comment', field: 'House Comment_10'},
        {headerName: 'TimeStamp', field: 'TimeStamp_8', valueGetter: (params)=>formatDateString(params.data['TimeStamp_8'], "MM/DD/yyyy hh:mm:ss")},
        {headerName: 'Approval Id', field: 'Approval Id_5'},
        {headerName: 'Account', field: 'Account_6'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['Stock Loan Accruals', [
        {headerName: 'Class', field: 'Class_3'},
        {headerName: 'AccrualDate', field: 'AccrualDate_4', valueGetter: (params)=>stringToDate(dateToString(params.data['AccrualDate_4']))},
        {headerName: 'Symbol', field: 'Symbol_5'},
        {headerName: 'Name', field: 'Name_15'},
        {headerName: 'Type', field: 'Type_3'},
        {headerName: 'Quantity', field: 'shsQuantity_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'BrwPrice', field: 'pxBrwPrice_5', valueFormatter: numberFormatter, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'LoanValue', field: 'mnyLoanValue_6', valueFormatter: numberFormatter, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Rate', field: 'rtRate_5', valueFormatter: numberFormatter, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'Accrual', field: 'mnyAccrual_6', valueFormatter: numberFormat3FractionalDigits, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'CCY', field: 'CCY_2'},
        {headerName: 'CCYPx', field: 'CCYPx_3'},
        {headerName: 'Close', field: 'pxClose_3', isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'Fx', field: 'pxFx_3', isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'HTB', field: 'HTB_5'},
        {headerName: 'Account', field: 'Account_5'},
        {headerName: 'Comments', field: 'Comments'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['Tax Lots - Closed', [
        {headerName: 'Class', field: 'Class_4'},
        {headerName: 'CCY', field: 'CCY_3'},
        {headerName: 'Trade Date', field: 'Trade Date_4', valueGetter: (params)=>stringToDate(dateToString(params.data['Trade Date_4']))},
        {headerName: 'Close Date', field: 'Close Date_4', valueGetter: (params)=>stringToDate(dateToString(params.data['Close Date_4']))},
        {headerName: 'Symbol', field: 'Symbol_5'},
        {headerName: 'Security Description', field: 'Security Description_12'},
        {headerName: 'Term', field: 'Term_2'},
        {headerName: 'Lot Nbr', field: 'Lot Nbr_4'},
        {headerName: 'Quantity', field: 'shsQuantity_5', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'CostPx', field: 'pxCostPx_5', valueFormatter: numberFormat3FractionalDigits},
        {headerName: 'CostValue', field: 'mnyCostValue_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'CloseValue', field: 'mnyCloseValue_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Realized', field: 'mnyRealized_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'Realized(USD)', field: 'mnyRealized(USD)_7', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'ROI', field: 'rtROI_5', valueFormatter: numberFormat4FractionalDigitsPer, cellStyle: cellStyleChecker},
        {headerName: 'Cntrib', field: 'rtCntrib_5', valueFormatter: numberFormat4FractionalDigitsPer, cellStyle: cellStyleChecker},
        {headerName: 'Account', field: 'Account_4'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['Tax Lots - Open', [
        {headerName: 'Class', field: 'Class_3'},
        {headerName: 'Type', field: 'Type_3'},
        {headerName: 'CCY', field: 'CCY_3'},
        {headerName: 'Symbol', field: 'Symbol_5'},
        {headerName: 'Security Description', field: 'Security Description_12'},
        {headerName: 'Trade Date', field: 'Trade Date_3', valueGetter: (params)=>stringToDate(dateToString(params.data['Trade Date_3']))},
        {headerName: 'Term', field: 'Term_2'},
        {headerName: 'Quantity', field: 'shsQuantity_4', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'PxCost', field: 'pxCostPx_4', isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'CurrPx', field: 'pxCurrPx_4', isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'CostValue', field: 'mnyCostValue_4', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'CurrValue', field: 'mnyCurrValue_4', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'Unrealized', field: 'mnyUnrealized_4', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'FXCost', field: 'fxCostFx_4', isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'CostValue(USD)', field: 'mnyCostValue(USD)_5', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Unrealized(USD)', field: 'mnyUnrealized(USD)_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'ROI', field: 'rtROI_3', valueFormatter: numberFormat5FractionalDigits, cellStyle: cellStyleChecker, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'Fx(USD)', field: 'fxFx(USD)_4', valueFormatter: numberFormat5FractionalDigits,cellStyle: cellAlignment, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'Activity', field: 'Activity_3'},
        {headerName: 'Lot Nbr', field: 'Lot Nbr_4'},
        {headerName: 'Account', field: 'Account_4'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['Trade Breaks', [
        {headerName: 'Break Type', field: 'Break Type_6'},
        {headerName: 'Account', field: 'Account_5'},
        {headerName: 'Trade Date', field: 'Trade Date_4',valueGetter: (params)=>stringToDate(dateToString(params.data['Trade Date_4']))},
        {headerName: 'Settle Date', field: 'Settle Date_4',valueGetter: (params)=>stringToDate(dateToString(params.data['Settle Date_4']))},
        {headerName: 'BS', field: 'BS_3'},
        {headerName: 'Security', field: 'Security_5'},
        {headerName: 'Price', field: 'pxPrice_4', isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'Quantity', field: 'shsQuantity_5', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'NetValue', field: 'mnyNetValue_5', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'CCY', field: 'ccy'},
        {headerName: 'Interest', field: 'mnyInterest_4', valueFormatter: numberFormat5FractionalDigits, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Commission', field: 'mnyComm_5', valueFormatter: numberFormat5FractionalDigits, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'EXEC BRK', field: 'Exec Brk_3'},
        {headerName: 'Broker Code', field: 'BrokerCode'},
        {headerName: 'DTCControlNumber', field: 'DTCControlNumber_5'},
        {headerName: 'Side', field: 'Side_3'},
        {headerName: 'Reason', field: 'Reason_10'}
    ]],
    ['Trades', [
        {headerName: 'Class', field: 'Class_3'},
        {headerName: 'Type', field: 'Type_3'},
        {headerName: 'CCY', field: 'CCY_3'},
        {headerName: 'BS', field: 'BS_2'},
        {headerName: 'SS', field: 'SS_2'},
        {headerName: 'OC', field: 'OC_2'},
        {headerName: 'Symbol', field: 'Symbol_8'},
        {headerName: 'Security Description', field: 'Security Description_12'},
        {headerName: 'Trade Date', field: 'Trade Date_4', valueGetter: (params)=>stringToDate(dateToString(params.data['Trade Date_4']))},
        {headerName: 'Quantity', field: 'shsQuantity_5', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Price', field: 'pxPrice_4', valueFormatter: numberFormat3FractionalDigits,cellStyle: cellAlignment, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'Amount', field: 'mnyAmount_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'COMM', field: 'mnyComm_5', valueFormatter: numberFormat3FractionalDigits, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'TcktChg', field: 'mnyTcktChg_5', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Fee', field: 'mnyFee_5', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Interest', field: 'mnyInterest_4', valueFormatter: numberFormat3FractionalDigits, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'NetValue', field: 'mnyNetValue_5', valueFormatter: numberFormatter, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Settle Date', field: 'Settle Date_4', valueGetter: (params)=>stringToDate(dateToString(params.data['Settle Date_4']))},
        {headerName: 'Entry Date', field: 'Entry Date_4', valueGetter: (params)=>stringToDate(dateToString(params.data['Entry Date_4']))},
        {headerName: 'Exec Brk', field: 'Exec Brk_3'},
        {headerName: 'CONTRA', field: 'Contra_5'},
        {headerName: 'Security', field: 'Security_5'},
        {headerName: 'Tran', field: 'Tran_3'},
        {headerName: 'Account', field: 'Account_4'},
        {headerName: 'Comment', field: 'Comment_1'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true},
    ]],
    ['Transactions', [
        {headerName: 'CCY', field: 'CCY_3'},
        {headerName: 'Activity Type', field: 'Activity Type_4'},
        {headerName: 'Trade Date', field: 'Trade Date_4', valueGetter: (params)=>stringToDate(dateToString(params.data['Trade Date_4']))},
        {headerName: 'Settle Date', field: 'Settle Date_4', valueGetter: (params)=>stringToDate(dateToString(params.data['Settle Date_4']))},
        {headerName: 'Entry Date', field: 'Entry Date_4', valueGetter: (params)=>stringToDate(dateToString(params.data['Entry Date_4']))},
        {headerName: 'Activity', field: 'Activity_5'},
        {headerName: 'Security Description', field: 'Security Description_12'},
        {headerName: 'Security', field: 'Security_5'},
        {headerName: 'TDBalance', field: 'mnyTDBalance_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Quantity', field: 'shsQuantity_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Amount', field: 'mnyAmount_6', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'PxPrice', field: 'pxPrice_5', valueFormatter: numberFormat3FractionalDigits,cellStyle: cellAlignment, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'Comm', field: 'mnyComm_4', valueFormatter: numberFormat3FractionalDigits, cellStyle: cellStyleChecker},
        {headerName: 'Fee', field: 'mnyFee_4', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'TcktChg', field: 'mnyTcktChg_4', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Trailer', field: 'Trailer_5'},
        {headerName: 'Tran', field: 'Tran_3'},
        {headerName: 'FX(USD)', field: 'fxFx(USD)_4', valueFormatter: numberFormat5FractionalDigits,cellStyle: cellAlignment, isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'Account', field: 'Account_4'},
        {headerName: 'Comments', field: 'Comments_1'},
        {headerName: 'Action', field: 'Action_10'},
        {headerName: 'Source', field: 'Source_5'},
        {headerName: 'Received', field: 'shsReceived_6', valueFormatter: numberFormatter, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Delivered', field: 'shsDelivered_6', valueFormatter: numberFormatter, isSumEnabled:true, isAvgEnabled:false},
        {headerName: '', field: '', type: 'flexColType'}
    ]],
    ['Upcoming Dividends', [
        {headerName: 'Class', field: 'Class_3'},
        {headerName: 'Type', field: 'Type_2'},
        {headerName: 'CCY', field: 'Ccy_3'},
        {headerName: 'Symbol', field: 'Symbol_3'},
        {headerName: 'Security', field: 'Security_5'},
        {headerName: 'Security Description', field: 'Security Description_10'},
        {headerName: 'Quantity', field: 'shsQuantity_4', valueFormatter: numberFormatter, cellStyle: cellStyleChecker, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'Dividend', field: 'pxDividend_4', isSumEnabled:false, isAvgEnabled:true},
        {headerName: 'EstIncome', field: 'mnyEstIncome_4', valueFormatter: numberFormatter, isSumEnabled:true, isAvgEnabled:false},
        {headerName: 'ExDate', field: 'ExDate_3'},
        {headerName: 'RecDate', field: 'RecDate_3'},
        {headerName: 'PayDate', field: 'PayDate_3'},
        {headerName: 'PayMethod', field: 'PayMethod_3'},
        {headerName: 'Account', field: 'Account_4'},
        {headerName: 'Comments', field: 'Comments_1'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['ABF - Long Position', [
        {headerName: 'Effective Date', field: 'effective_date',valueFormatter: formatDateStringToDate,},
        {headerName: 'Account', field: 'account'},
        {headerName: 'Fund Name', field: 'fundName'},
        {headerName: 'Long Name', field: 'name_long'},
        {headerName: 'Position Raw Type', field: 'position_raw_type'},
        {headerName: 'CCY', field: 'CCY'},
        {headerName: 'Security Code', field: 'Security_code'},
        {headerName: 'Client Asset class', field: 'client_asset_class'},
        {headerName: 'Symbol', field: 'symbol'},
        {headerName: 'Security Description', field: 'security_description'},
        {headerName: 'Quantity SD', field: 'shsQuantitySD', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'Value SD', field: 'mnyValueSD', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'Price', field: 'pxPrice', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'CCY PX', field: 'ccy_px'},
        {headerName: 'FX(USD)', field: 'fxFxUSD', valueFormatter: numberFormat5FractionalDigits,cellStyle: cellAlignment},
        {headerName: 'Value SD USD', field: 'mnyValueSDUSD', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['ABF - Debit Summary', [
        {headerName: 'Effective Date', field: 'effective_date',valueFormatter: formatDateStringToDate,},
        {headerName: 'Account', field: 'account'},
        {headerName: 'Account Name', field: 'account_name'},
        {headerName: 'Client Asset Class', field: 'client_asset_class'},
        {headerName: 'Balance', field: 'MnyBalance', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'Rate', field: 'rate', cellStyle: cellStyleChecker, valueFormatter: numberFormatter},
        {headerName: 'Single Day', field: 'single_day', valueFormatter: numberFormat3FractionalDigits, cellStyle: cellStyleChecker},
        {headerName: 'MTD Accrual', field: 'mtd_accural', valueFormatter: numberFormat3FractionalDigits, cellStyle: cellStyleChecker},
        {headerName: 'YTD Accrual', field: 'ytd_accural', valueFormatter: numberFormat3FractionalDigits, cellStyle: cellStyleChecker},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],
    ['ABF - Top Summary', [
        {headerName: 'Effective Date', field: 'effective_date',valueFormatter: formatDateStringToDate,},
        {headerName: 'Account', field: 'account'},
        {headerName: 'Account Name', field: 'account_name'},
        {headerName: 'Description', field: 'description'},
        {headerName: 'Balance', field: 'MnyBalance', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'Rate', field: 'rate', cellStyle: cellStyleChecker, valueFormatter: numberFormatter},
        {headerName: 'Single Day', field: 'single_day', valueFormatter: numberFormat3FractionalDigits, cellStyle: cellStyleChecker},
        {headerName: 'MTD Accrual', field: 'mtd_accural', valueFormatter: numberFormat3FractionalDigits, cellStyle: cellStyleChecker},
        {headerName: 'YTD Accrual', field: 'ytd_accural', valueFormatter: numberFormat3FractionalDigits, cellStyle: cellStyleChecker},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel:true}
    ]],

]);

function appendFilter(originalMap, newObject) {
    const newMap = new Map();
    originalMap.forEach((array, key)=>{
        const updatedArray = array.map(item=>({
                ...item,
                ...newObject
            }));
        newMap.set(key, updatedArray)
    })
    return newMap;
}