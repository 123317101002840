import React, {useState, useRef, useEffect, useContext, useMemo} from 'react';
import {Document, Page} from 'react-pdf';
import {useReactToPrint} from 'react-to-print';
import {TransformWrapper, TransformComponent} from "react-zoom-pan-pinch";
import {Container, Row, Col} from "react-bootstrap";
import ReportSearchParamBadge from "./ReportSearchParamBadge";
import AgGridToolbar from "components/AgGridToolbar/AgGridToolbar";
import useWindowDimensions from "hooks/Dimensions";
import PageHeader from "components/PageHeader";
import {saveAs} from 'file-saver';
import {ReportsPanelContext} from "./context";
import {ActionPanelContext} from "context/action";
import {SecurityContext} from "context/security";
import {REPORT_SAVED_REPORTS_URL} from "service/UrlConstant";
import {ToastContext} from "context/Toast";
import {dateToString} from "utils/FormatUtils";
import {EntityAccountsContext} from "components/entity-accounts/context";
import {REPORTS_PANEL_VIEW_REPORT_PAGE} from "./context/ReportsPanelContext";

export default function ReportPdfPanel() {

    const {
        reportsPdfDisplayState,
        setReportsPdfDisplayState,
        reportsPanelPageState,
        clearReportSearchAndLoadReportsHome,
        showGridToolbar
    } = useContext(ReportsPanelContext);

    const {
        makePutRequest,
        makeDeleteRequest,
        makePostRequest
    } = useContext(SecurityContext);

    const {
        setHandleSaveFunction,
        setHandleRenameFunction,
        setHandleDeleteFunction,
        setSavedSearchName
    } = useContext(ActionPanelContext);

    const {
        clearEntityAccounts,
    } = useContext(EntityAccountsContext);

    const {success, error} = useContext(ToastContext);

    const {isMobile} = useWindowDimensions();

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handlePrintFunction = ()=>{
        setIsPrintMode(true);
        setTimeout(()=>{
            handlePrint();
            setIsPrintMode(false);
        },500);
    }

    const zoomInRef = useRef(null);
    const zoomOutRef = useRef(null);
    const pageRefs = useRef([]);
    const inputRef = useRef(null);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [localPageNumber, setLocalPageNumber] = useState(1);
    const [isPrintMode, setIsPrintMode] = useState(false);
    const [deskTopScale, setDesktopScale] = useState(1.0);

    const saveReportSearch = (savedName, gridApi, onSuccess, onError) => {
        const onSuccessResponse = (response) => {
            if (onSuccess) onSuccess();
            reportsPdfDisplayState.isReportSavedSearch = true;
            reportsPdfDisplayState.reportSavedSearchName = response.data.savedName;
            reportsPdfDisplayState.reportSavedSearchId = response.data.savedReportId;
            setReportsPdfDisplayState(reportsPdfDisplayState);
            setSavedSearchName(savedName);
            success("Successfully created saved report search");
        }

        const onErrorResponse = (err) => {
            console.error(err);
            if(onError) onError(err);
        }

        makePostRequest(
            {
                savedName: savedName,
                clientCode: reportsPdfDisplayState.reportPdfSearchData.clientCode,
                managerId: reportsPdfDisplayState.reportPdfSearchData.managerId,
                subManagerId: reportsPdfDisplayState.reportPdfSearchData.subManagerId,
                accountId: reportsPdfDisplayState.reportPdfSearchData.accountId,
                effectiveDate: dateToString(reportsPdfDisplayState.reportPdfSearchData.effectiveDate.toString()),
                folder: reportsPdfDisplayState.reportPdfSearchData.folder,
                fileName: reportsPdfDisplayState.reportPdfSearchData.fileName
            },
            REPORT_SAVED_REPORTS_URL, onSuccessResponse, onErrorResponse);
    };

    const updateReportSavedSearch = (updatedSavedName, gridApi, onSuccess, onError) => {

        const param = {
            savedName: updatedSavedName,
            savedReportId: reportsPdfDisplayState.reportSavedSearchId,
            clientCode: reportsPdfDisplayState.reportPdfSearchData.clientCode,
            managerId: reportsPdfDisplayState.reportPdfSearchData.managerId,
            subManagerId: reportsPdfDisplayState.reportPdfSearchData.subManagerId,
            accountId: reportsPdfDisplayState.reportPdfSearchData.accountId,
            effectiveDate: dateToString(reportsPdfDisplayState.reportPdfSearchData.effectiveDate.toString()),
            folder: reportsPdfDisplayState.reportPdfSearchData.folder,
            fileName: reportsPdfDisplayState.reportPdfSearchData.fileName
        }

        const onSuccessResponse = (response) => {
            if (onSuccess) onSuccess();
            reportsPdfDisplayState.reportSavedSearchName = updatedSavedName;
            setReportsPdfDisplayState(reportsPdfDisplayState);
            setSavedSearchName(updatedSavedName);
            success("Successfully updated saved report search");
        }

        const onErrorResponse = (err) => {
            console.error(err);
            if (onError) onError(err);
        }

        makePutRequest(param,
            REPORT_SAVED_REPORTS_URL + "/" + reportsPdfDisplayState.reportSavedSearchId, onSuccessResponse, onErrorResponse);
    };

    const deleteReportSavedSearch = (gridApi, onSuccess, onError) => {

        const onSuccessResponse = (response) => {
            if (onSuccess) onSuccess();
            clearEntityAccounts();
            clearReportSearchAndLoadReportsHome();
            success("Report search successfully deleted");
        }

        const onErrorResponse = (err) => {
            console.error(err);
            if (onError) onError(err);
            error("Error occurred with deleting saved report search");
        }

        makeDeleteRequest(
            REPORT_SAVED_REPORTS_URL + "/" + reportsPdfDisplayState.reportSavedSearchId, onSuccessResponse, onErrorResponse);
    };

    useEffect(() => {
        setLocalPageNumber(1)
        if (REPORTS_PANEL_VIEW_REPORT_PAGE === reportsPanelPageState.currentPage) {
            setHandleRenameFunction({handleRename: updateReportSavedSearch, gridApi: null});
            setHandleSaveFunction({handleSave: saveReportSearch, gridApi: null});
            setHandleDeleteFunction({handleDelete: deleteReportSavedSearch, gridApi: null});
        }
    },[reportsPanelPageState,reportsPdfDisplayState])
useEffect(()=>{
    if(inputRef.current){
        inputRef.current.focus();
    }
}, [inputRef, localPageNumber])

    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
        pageRefs.current = Array(numPages).fill().map(()=>React.createRef());
    }

    function pageTitle() {
        return ("Report");
    }

    function showPdfDocument() {
        const pdfNumPagesArray = [...Array(numPages).keys()];
        return (<>
            <Document ref={componentRef} file={reportsPdfDisplayState.reportPdfData} pageMode={"fullScreen"} pageLayout={"twoPageLeft"}
                      onLoadSuccess={onDocumentLoadSuccess} onLoadError={console.error} style={{scrollable:"auto"}}>
                {isPrintMode? pdfNumPagesArray.map(( index)=>(
                        <div className='printLayoutPage'>
                            <Page key={index + 1} pageNumber={index + 1} scale={isMobile ? 0.5 : deskTopScale} renderMode={"svg"} renderTextLayer={false}/>
                        </div>
                    )):<div style={{margin:0, height:"700px", overflowY:"auto", overflowX:"auto"}}>
                    {Array.apply(null, Array(numPages))
                        .map((x, i)=>i+1)
                        .map(page => {
                            return <div
                                className={'m-0 p-0'}
                                key={page}
                                ref={pageRefs.current[page-1]}>
                            <Page key={page}  pageNumber={page} scale={deskTopScale} renderMode={"svg"} renderTextLayer={false}/>
                            </div>
                        })}
            </div> }
            </Document>
        </>)
    }

    const RenderPdfDocument = useMemo(() =>
            showPdfDocument,
        [reportsPdfDisplayState.reportPdfData, deskTopScale, isPrintMode, numPages]);
    const goToPage = (pageNumber)=>{
        if(pageNumber >=1 && pageNumber <= numPages && !!pageRefs.current[pageNumber-1].current){
            pageRefs.current[pageNumber-1].current.scrollIntoView({
                behavior:"smooth",
                block:"start",
                inline:"nearest"
            })
            setPageNumber(pageNumber)
        }
    }
    const goToPageComponent = ()=>{
        return <><div style={{textAlign:"left"}}>
            <span>Go to page:</span>
            <input type="number"
                   className={"ml-2 mr-1"}
                   value={localPageNumber}
                   onChange={(e)=>setLocalPageNumber(e.target.value)}
                   name={'goToPage'}
                   ref = {inputRef}
                   onKeyPress={(e)=>{
                       if(e.key ==="Enter"){
                           goToPage(Number(e.target.value));
                       }
                   }}
                   min={1}
                   max={numPages}
                   style={{height:"25px", width:"50px"}}
            />
            of {numPages}
        </div>
        </>
    }

    function PagePREV() {
        return (<>
            {pageNumber > 1 && <a className="linkColor" href="#" aria-disabled={pageNumber === 1} onClick={() => {
                if (pageNumber > 1) {
                    setPageNumber(pageNumber - 1)
                } else {
                    setPageNumber(1)
                }
            }}>
                <i className="td-icon icon-small td-icon-arrowLeft2"></i>
            </a>}
        </>)
    }

    function PageNext() {
        return (<>
            {pageNumber < numPages && <a className="linkColor" href="#" aria-disabled={pageNumber === numPages}
                                         onClick={() => {
                                             if (numPages > pageNumber) {
                                                 setPageNumber(pageNumber + 1)
                                             } else {
                                                 setPageNumber(1)
                                             }
                                         }}><i className="td-icon icon-small td-icon-arrowRight2"></i>
            </a>}
        </>)
    }

    function downloadReportToFile() {
        let blob = new Blob([reportsPdfDisplayState.reportPdfData.data], {type: "application/octet-stream"});
        saveAs(blob, reportsPdfDisplayState.reportPdfSearchData.fileName);
    }

    const DownloadPDF = () => {
        return (<>
            <a onClick={downloadReportToFile} className="linkColor"><span
                className="td-icon icon-small td-icon-download"></span></a>
        </>)
    }

    function PrintPDF() {
        return (<>
            <a onClick={() => {
                handlePrintFunction()
            }}>
                <span className="td-icon icon-small td-icon-printer"></span>
            </a>
        </>)
    }

    function ZoomInPDF() {
        return (<>
            <a onClick={() => {
                if(isMobile){
                    zoomInRef.current.click()
                }else{
                    setDesktopScale(scale => scale * 1.1);
                }
            }}>
                <i className="bi bi-zoom-in"></i>
            </a>
        </>)
    }

    function ZoomOutPDF() {
        return (<>
            <a onClick={() => {
                if(isMobile){
                    zoomOutRef.current.click()
                }else{
                    setDesktopScale(scale => scale / 1.1)
                }
            }}>
                <i className="bi bi-zoom-out"></i>
            </a>
        </>)
    }

    function Pagination() {
        return (<>
            <Container style={{width: "100%", color: "white", padding: 0}} fluid>
                <Row style={{width: "100%", marginLeft: 0, marginRight: 0}}>

                    <Col xs={6}>
                        <Container style={{color: "white"}} fluid>
                            <Row style={{color: "white", padding:0}}>
                                <Col>
                                    {goToPageComponent()}
                                </Col>
                            </Row>

                        </Container>
                    </Col>
                    <Col xs={6}>
                        <Container style={{color: "white"}} fluid>
                            <Row className={"flex-row-reverse"}>

                                <Col xs={1}>
                                    <PrintPDF/>
                                </Col>
                                <Col xs={1}>
                                    <DownloadPDF/>
                                </Col>

                                <Col xs={1}>
                                    <ZoomOutPDF/>
                                </Col>
                                <Col xs={1}>
                                    <ZoomInPDF/>
                                </Col>
                            </Row>

                        </Container>
                    </Col>
                </Row>
            </Container>
        </>)
    }
    return (<>
        <Container fluid>
            <PageHeader text={pageTitle()}>
                <ReportSearchParamBadge reportsPdfDisplayState={reportsPdfDisplayState}/>
            </PageHeader>

            {showGridToolbar && <AgGridToolbar reportScreen={true}
                                               title={reportsPdfDisplayState.reportSavedSearchName}
                                               isSaved={reportsPdfDisplayState.isReportSavedSearch}/>}

            <Container style={{

            }} fluid>
                <Row style={{paddingBottom: 0}}>
                    <Col style={{
                        textAlign: "center",
                        alignSelf: "center",
                        background: "#32353a",
                        width: "100%",
                        margin: 0,
                        padding: 0,
                        color: "#fdfdfe"
                    }} sm={12}>
                        <Pagination/>
                    </Col>
                </Row>
                <Row style={{paddingTop: 0}}>
                    <Col id="test" style={{
                        padding: 0,
                        alignContent: "center",
                        textAlign: "center",
                        alignSelf: "center"
                    }}>
                        {isMobile? <TransformWrapper defaultScale={1} defaultPositionX={100}
                                                     defaultPositionY={200} >
                            {({zoomIn, zoomOut, ...rest}) => (
                                <>
                                <span style={{display: "none"}}>
                                <a onClick={() => {
                                    zoomIn()
                                }} ref={zoomInRef}><i className="bi bi-zoom-in"></i></a>
                                <a onClick={() => {
                                    zoomOut()
                                }} ref={zoomOutRef}><i className="bi bi-zoom-out"></i></a>
                                </span>

                                    <TransformComponent>
                                        <RenderPdfDocument/>
                                    </TransformComponent>
                                </>
                            )}
                        </TransformWrapper> : <div>
                            <RenderPdfDocument/>
                        </div>}
                    </Col>
                </Row>
            </Container>
        </Container>
    </>);
}