import {addPropertiesToGridColumns} from "./gridColumnUtils";
import {GRID_COLUMN_PROPERTY} from "./Constants";

export function createExcelExportParams (sheetName, columnNames) {
    if (typeof sheetName === 'object' || sheetName == null || sheetName === '') {
        sheetName = 'Customer Portal'; //Max is 31 chars
    }
    return {sheetName: sheetName, columnKeys: columnNames};
}

export const getFilteredColumns = (grid) => {
    let filteredColumns = (grid && grid.current.columnApi )?
        [...grid.current.columnApi.getAllDisplayedColumns()]: [];
    return filteredColumns.length === 0 ? [] : filteredColumns.map(item => {
        return item.getColId()
    });
}


export const updateColumnHideState = (columnDefs, columnState)=> {
    let updatedColumnDefs;
    if(columnDefs !== null && columnDefs !== undefined && columnDefs?.length > 0)
        updatedColumnDefs = JSON.parse(JSON.stringify(columnDefs));

    if (columnState?.columnState &&
        Array.isArray(columnState?.columnState) &&
        columnState?.columnState?.length > 0) {
        updatedColumnDefs?.forEach((colDef)=>{
            const match = columnState.columnState.find(state => state.colId === colDef.field)
            if(match) {
                colDef.hide = match.hide
                colDef.sort = match.sort || null
            }
        })

    } else if(Array.isArray(columnState?.colIds)  && columnState?.colIds?.length > 0 ) {
        updatedColumnDefs = updateColumnIds(columnState, updatedColumnDefs)
    }

    else {
        updatedColumnDefs.forEach((colDef)=> {
            colDef.hide = colDef.field === 'tradeCancelIndicator' ||
                colDef.field === 'cdBlockIdClient' || colDef.field === 'version' || colDef.field === 'migrated';
            colDef.sort = null;
        })
    }
    updatedColumnDefs = addPropertiesToGridColumns(updatedColumnDefs, GRID_COLUMN_PROPERTY)
    return updatedColumnDefs;
}

export const updateColumnIds = (columnState, columnDefs) =>{
    const updateColumns = JSON.parse(JSON.stringify(columnDefs));
    if(Array.isArray(columnState?.colIds)  && columnState?.colIds?.length > 0 ) {
        updateColumns?.forEach((colDef)=>{
            colDef.hide = !columnState.colIds.find(col => col === colDef.field)
        })
    }
    return updateColumns;
}